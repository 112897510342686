import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { Icon2Claws } from "components/Icons/Icon2Claws";
import { Icon3Claws } from "components/Icons/Icon3Claws";
import { IconCamera } from "components/Icons/IconCamera";
import { IconXCamera } from "components/Icons/IconXCamera";
import { NAIcon } from "components/Icons/NAIcon";
import SwitchType from "components/Switch";
import { DEDICATED_TO_OPTIONS, STATUS } from "constants/constants";
import { useCallback, useMemo } from "react";
import { renderDedicated } from "views/Machine/MachineSettings/help";

export default function MachineMapItem({
  item,
  machine,
  handleOpenDetail,
  handleSwitchStatus,
}) {
  const textNA = "N/A";
  const IconStatus = useCallback(() => {
    let bg = "#90FA87";
    let bgBorder = "#0DAA0A";
    if (item?.status === STATUS.DISCONNECT) {
      bg = "#FF003D";
      bgBorder = "#C30331";
    } else if (item?.status === STATUS.NO_ACTIVE) {
      bg = "#D2D2D2";
      bgBorder = "#A9ADA9";
    }
    return (
      <Box
        bg={bg}
        w="22px"
        h="22px"
        borderRadius="100%"
        border={`3px solid ${bgBorder}`}
      />
    );
  }, [item?.status]);

  const isStatusActive = useMemo(() => {
    return item?.status === STATUS.ACTIVE;
  }, [item?.status]);

  const isStateActive = useMemo(() => {
    return machine?.status === STATUS.ACTIVE;
  }, [machine?.status]);

  const renderBgItem = useMemo(() => {
    if (item?.status === STATUS.ACTIVE) {
      if (isStateActive) {
        return "linear-gradient(140deg, #99E177 3.67%, #3AC33A 96.33%)";
      }
      return "linear-gradient(139.88deg, #FFE455 3.6%, #FC9012 96.4%)";
    }
    return "linear-gradient(137deg, #EDE6E6 1.63%, #C0BDBD 98.37%)";
  }, [item?.status, isStateActive]);

  const is3Claws = useMemo(() => {
    return machine?.machine_type === STATUS.THREE_CLAWS;
  }, [machine?.machine_type]);

  const isCameraFOK = useMemo(() => {
    return item?.status_cam_front === STATUS.OK;
  }, [item?.status_cam_front]);

  const isCameraSOK = useMemo(() => {
    return item?.status_cam_back === STATUS.OK;
  }, [item?.status_cam_back]);

  const IconClaw = useMemo(() => {
    if (machine) {
      return is3Claws ? Icon3Claws : Icon2Claws;
    }
    return NAIcon;
  }, [machine]);

  return (
    <Box
      w="190px"
      h="190px"
      borderRadius="10px"
      boxShadow="0px 4px 4px 0px #00000040"
      p="10px"
      bg={renderBgItem}
      pointerEvents={isStatusActive ? "auto" : "none"}
    >
      <Flex direction="column" gap={2}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" gap={2}>
            <IconStatus />
            <Text fontSize="18px" fontWeight="400">
              {item?.mac?.slice(-4)}
            </Text>
          </Flex>
          <SwitchType
            color="#285239"
            colorThumb="#2EF7F7"
            isChecked={isStatusActive ? isStateActive : false}
            onChange={handleSwitchStatus}
          />
        </Flex>
        <Text
          fontWeight="700"
          fontSize="13px"
          color="#2D377B"
          bg=" #FFFFFF"
          w="fit-content"
          borderRadius="5px"
          p="1px 6px"
          opacity={isStatusActive ? 1 : "0.3"}
        >
          {machine
            ? DEDICATED_TO_OPTIONS.find(
                (item) =>
                  item?.value ===
                  renderDedicated(
                    machine?.event_available,
                    machine?.freeplay_available
                  )
              )?.label
            : textNA}
        </Text>
        <Flex alignItems="center" gap={2}>
          <Icon fontSize="70px" as={IconClaw} />
          <Flex direction="column" opacity={isStatusActive ? 1 : "0.3"}>
            <Text fontWeight="700" fontSize="13px">
              Gameplay
            </Text>
            <Text fontWeight="400" fontSize="13px">
              {machine ? <>{is3Claws ? "3-claw" : "2-claw"} lottery</> : textNA}
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems="center" justifyContent="center" gap={12}>
          <Box
            position="relative"
            cursor={isStatusActive ? "pointer" : "not-allowed"}
            opacity={isStatusActive ? 1 : "0.3"}
            onClick={() => {
              if (isStatusActive) {
                handleOpenDetail(STATUS.FRONT);
              }
            }}
          >
            {!isCameraFOK && (
              <Icon
                fontSize="8px"
                position="absolute"
                top="4px"
                left="4px"
                as={IconXCamera}
              />
            )}
            <Icon fontSize="36px" as={IconCamera} />
            <Text
              color="#2D377B"
              fontWeight="700"
              fontSize="13px"
              position="absolute"
              top="5px"
              left="10px"
            >
              F
            </Text>
          </Box>
          <Box
            position="relative"
            cursor={isStatusActive ? "pointer" : "not-allowed"}
            opacity={isStatusActive ? 1 : "0.3"}
            onClick={() => {
              if (isStatusActive) {
                handleOpenDetail(STATUS.BACK);
              }
            }}
          >
            {!isCameraSOK && (
              <Icon
                fontSize="8px"
                position="absolute"
                top="4px"
                left="4px"
                as={IconXCamera}
              />
            )}
            <Icon fontSize="36px" as={IconCamera} />
            <Text
              color="#2D377B"
              fontWeight="700"
              fontSize="13px"
              position="absolute"
              top="5px"
              left="10px"
            >
              S
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
