import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getGamePlayType } from "api/gameplayType.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import MachineCreateAndUpdate from "./components/createAndUpdate";
import ConditionMachine from "./condition";
import MachineRowGamePlay from "./row";

export default function MachineGamePlay() {
  const [isLoading, setIsLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);

  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const [machineGamePlay, setMachineGamePlay] = useState([]);

  const labels = [
    "Name",
    "Last Modified At",
    "Last Modified By",
    "Created At",
    "Created By",
    "",
  ];
  const textColor = useColorModeValue("#344767", "white");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    onClose();
    setDataDetail(null);
  };

  const handleOpenCreateOrUpdate = (data = null) => {
    setDataDetail(data);
    onOpen();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const getListGame = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const { data } = await getGamePlayType({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setMachineGamePlay(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  useEffect(() => {
    getListGame();
  }, [getListGame]);

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(machineGamePlay);
    } else {
      setCheckedIds([]);
    }
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="20px" w="100%">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              Gameplay Type
            </Text>
            <ConditionMachine
              handleOpenCreateOrUpdate={handleOpenCreateOrUpdate}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              setParams={setParams}
              params={params}
            />
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck
            isChecked={
              machineGamePlay?.length > 0 &&
              checkedIds.length === machineGamePlay?.length
            }
            onChangeChecked={onChangeChecked}
            dataRow={machineGamePlay}
          >
            <>
              {machineGamePlay?.map((item) => {
                return (
                  <MachineRowGamePlay
                    key={item?.id}
                    setCheckedIds={setCheckedIds}
                    checkedIds={checkedIds}
                    handleOpenCreateOrUpdate={() => {
                      handleOpenCreateOrUpdate(item);
                    }}
                    machine={item}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpen && (
        <MachineCreateAndUpdate
          isOpen={isOpen}
          onClose={handleClose}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
    </Flex>
  );
}
