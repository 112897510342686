/* eslint-disable no-fallthrough */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getOnlyDecimal } from "utils/number";
import schemaFee from "./schema";
import { noSpecialCharRegex } from "utils/regex";
import { removeEmojis } from "utils/string";
import {
  addRevenueSharingApi,
  editRevenueSharingByIdApi,
} from "stores/revenueSharing/action";
import ButtonType from "components/Button";

export default function RevenueSharingCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  handleViewClose,
}) {
  const { t } = useTranslation("revenueSharing");
  const textColor = useColorModeValue("#344767", "white");
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [valPayee, setValPayee] = useState(() =>
    dataDetail ? dataDetail?.payee : ""
  );
  const [valPayeeJP, setValPayeeJP] = useState(() => {
    const payeeJP =
      dataDetail?.translations?.find((data) => data.lang_key === "JP")?.payee ||
      "";
    return payeeJP ? payeeJP : "";
  });

  const form = useForm({
    resolver: yupResolver(schemaFee()),
    defaultValues: dataDetail,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = form;

  useEffect(() => {
    setValue("payee", valPayee);
  }, [valPayee]);

  useEffect(() => {
    setValue("payee_jp", valPayeeJP);
  }, [valPayeeJP]);

  useEffect(() => {
    if (tabIndex) {
      setValue("payee_jp", valPayeeJP);
    } else {
      setValue("payee", valPayee);
    }
  }, [tabIndex]);

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const handleClose = () => {
    reset();
    handleViewClose();
    onClose();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const request = {
      ...data,
      translations: [
        {
          revenue_sharing_id: null,
          payee: valPayeeJP,
          lang_key: "JP",
        },
        {
          revenue_sharing_id: null,
          payee: valPayee,
          lang_key: "EN",
        },
      ],
    };
    if (idEdit) {
      await dispatch(editRevenueSharingByIdApi({ ...request }));
    } else {
      await dispatch(addRevenueSharingApi({ ...request }));
    }
    reset();
    handleCreateOrUpdateSuccess();
  };

  const handleChangePayee = (e, type) => {
    let result = "";
    const str = removeEmojis(e.target.value);
    str?.split("")?.map((char) => {
      if (!noSpecialCharRegex.test(char)) {
        return;
      }
      return (result += char);
    });

    if (type === "EN") {
      setValPayee(result);
    } else {
      setValPayeeJP(result);
    }
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const renderForm = () => {
    return (
      <Box>
        <Tabs
          mb={10}
          index={tabIndex}
          onChange={handleTabsChange}
          variant="unstyled"
        >
          <TabList>
            <Tab
              fontSize="16px"
              fontWeight="700"
              border="1px solid #EEEFF2"
              boxShadow="none !important"
              borderRadius="5px"
              color={textColor}
              _selected={{
                color: "white",
                backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
              }}
            >
              English
            </Tab>
            <Tab
              fontSize="16px"
              fontWeight="700"
              border="1px solid #EEEFF2"
              boxShadow="none !important"
              borderRadius="5px"
              color={textColor}
              _selected={{
                color: "white",
                backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
              }}
            >
              Japanese
            </Tab>
          </TabList>
        </Tabs>

        <Box pl={20} pr={20}>
          <FormProvider {...form}>
            <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
              <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                {tabIndex === 0 ? (
                  <FormInput
                    label={"Payee"}
                    name="payee"
                    value={valPayee}
                    maxLength={30}
                    isRequired
                    onKeyPress={(event) => {
                      if (!noSpecialCharRegex.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      handleChangePayee(e, "EN");
                    }}
                  />
                ) : (
                  <FormInput
                    label={"受取人"}
                    name="payee_jp"
                    value={valPayeeJP}
                    maxLength={30}
                    isRequired
                    onKeyPress={(event) => {
                      if (!noSpecialCharRegex.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      handleChangePayee(e, "JP");
                    }}
                  />
                )}

                <FormInput
                  label={tabIndex === 1 ? "共有割合" : "Sharing Percentage"}
                  name="sharing_percentage"
                  maxLength={30}
                  onChange={(e) => {
                    setValue(
                      "sharing_percentage",
                      getOnlyDecimal(e.target.value)
                    );
                  }}
                  disabled={tabIndex === 1}
                  isRequired
                />
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px">
        <ModalHeader color={textColor} margin="auto">
          {idEdit
            ? t("edit_revenue_sharing_title")
            : t("create_revenue_sharing_title")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderForm()}</ModalBody>
        <ModalFooter margin="auto">
          <ButtonType
            text={t("btn_submit")}
            mt={4}
            btnType="primary"
            m="auto"
            w="120px"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            type="submit"
            form="machine-form"
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
