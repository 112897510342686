import {
  Button,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createMachineSetting,
  getListCraneMachines,
  updateMachineSetting,
} from "api/machine.api";
import FormSelect from "components/form/FormSelect";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  DEDICATED_TO_OPTIONS,
  MACHINE_TYPE_OPTIONS,
  STATE_OPTIONS,
} from "../constants";
import { renderDedicated } from "../help";
import schemaMachine from "./schema";
import ButtonType from "components/Button";
import { STATUS } from "constants/constants";

export default function MachineCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  listGamePlayType,
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#344767", "white");
  const [machinesOptions, setMachinesOptions] = useState([]);
  const form = useForm({
    resolver: yupResolver(schemaMachine()),
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const getMachinesCrane = async () => {
    try {
      const { data } = await getListCraneMachines({
        page: 1,
        limit: 999999,
      });
      if (data?.success) {
        const list = data?.data?.records;
        if (list && list?.length > 0) {
          const listOptions = list?.map((item) => {
            return {
              label: item?.mac,
              value: item?.id,
            };
          });
          setMachinesOptions(listOptions);
          if (dataDetail?.crane_machine_id) {
            setValue("machine_id", dataDetail?.crane_machine_id);
          } else {
            setValue(
              "machine_id",
              listOptions?.length > 0 ? listOptions[0].value : null
            );
          }
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getMachinesCrane();
    if (dataDetail) {
      reset({
        ...dataDetail,
        deDicatedTo: renderDedicated(
          dataDetail?.event_available,
          dataDetail?.freeplay_available
        ),
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      crane_machine_id: data?.machine_id,
      status: data?.status,
      machine_type: data?.machine_type,
      gameplay_type_id: data?.gameplay_type_id,
      freeplay_available: data?.deDicatedTo === STATUS.FREE,
      event_available: data?.deDicatedTo === STATUS.EVENT,
      front_cam_id: data?.front_cam_id || 1,
      back_cam_id: data?.back_cam_id || 1,
      domain_stream: "",
      position_id: "1",
    };
    try {
      if (idEdit) {
        const res = await updateMachineSetting(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Machine updated.",
            description: "Machine updated.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        }
      } else {
        const res = await createMachineSetting(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Machine created.",
            description: "Machine created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            {idEdit ? (
              <div>
                <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                  {"Machine ID "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Text ml={2} mt={4} fontSize="16px" fontWeight="400" color={textColor}>
                  {dataDetail?.crane_machine?.mac}
                </Text>
              </div>
            ) : (
              <FormSelect
                label="Machine ID"
                name="machine_id"
                isRequired
                defaultValue={
                  machinesOptions?.length > 0 ? machinesOptions[0].value : null
                }
                options={machinesOptions}
              />
            )}

            <FormSelect
              name="status"
              label="Machine State"
              defaultValue={STATE_OPTIONS[0].value}
              isRequired
              options={STATE_OPTIONS}
            />
            <FormSelect
              name="gameplay_type_id"
              label="Gameplay Type"
              isRequired
              defaultValue={
                listGamePlayType?.length > 0 ? listGamePlayType[0].value : null
              }
              options={listGamePlayType}
            />
            <FormSelect
              name="machine_type"
              label="Booth Type"
              defaultValue={MACHINE_TYPE_OPTIONS[0].value}
              isRequired
              options={MACHINE_TYPE_OPTIONS}
            />
            <FormSelect
              name="deDicatedTo"
              label="Dedicated to"
              defaultValue={STATUS.FREE}
              isRequired
              options={DEDICATED_TO_OPTIONS}
            />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1000px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "EDIT MACHINE" : "NEW MACHINE SETUP"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="120px"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            // onClick={() => {
            //   handleSubmit(onSubmit);
            // }}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
