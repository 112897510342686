import { Box, Flex, Icon, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { useMemo } from "react";
import { useFieldArray } from "react-hook-form";
import { MdClose, MdOutlineAdd } from "react-icons/md";
import { OPTIONS_REWARDS } from "views/Event/constant";
import NFTListEvent from "../NFTList";

export default function BoxFinalPrize({
  item,
  indexItem,
  control,
  watch,
  errorsFinal,
  defaultDataReward,
  removeFinalPrize,
  tickets,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue("#344767", "white");
  const useSubItemFields = (indexItem) => {
    return useFieldArray({
      control,
      name: `finalPrizes[${indexItem}].rewards`,
    });
  };

  const ItemReward = ({ indexSub, removeReward }) => {
    const watchItem = watch(
      `finalPrizes[${indexItem}].rewards.${indexSub}.item`
    );

    const isItemNFT = useMemo(() => {
      return watchItem === "NFT";
    }, [watchItem]);

    const isItemTicket = useMemo(() => {
      return watchItem === "TICKET";
    }, [watchItem]);

    const handleAddNFT = (data) => {
      console.log("ccc data", data);
    };

    const renderNFT = () => {
      return (
        <>
          <Text fontWeight="400" fontSize="16px" mt="12px" color="#344767">
            NFT
          </Text>
          <FormInput
            name={`finalPrizes[${indexItem}].rewards.${indexSub}.amount`}
            w="200px"
            placeholder="Search for NFT"
            onWheel={() => undefined}
            border="1px solid #EEEFF2"
            background="#F9FAFB"
            cursor="pointer"
            onClick={() => {
              onOpen();
            }}
          />
          <NFTListEvent
            isOpen={isOpen}
            onClose={onClose}
            handleAddNFT={handleAddNFT}
          />
        </>
      );
    };

    return (
      <Flex align="flex-start" gap={4} ml={8}>
        <Text fontWeight="400" fontSize="16px" mt="8px" color="#344767">
          Item
        </Text>
        <FormSelect
          name={`finalPrizes[${indexItem}].rewards.${indexSub}.item`}
          options={OPTIONS_REWARDS}
          defaultValue={OPTIONS_REWARDS[0].value}
          w="166px"
        />
        {isItemNFT ? (
          renderNFT()
        ) : (
          <>
            {isItemTicket && (
              <>
                <Text fontWeight="400" fontSize="16px" mt="8px" color="#344767">
                  Type
                </Text>
                <FormSelect
                  name={`finalPrizes[${indexItem}].rewards.${indexSub}.type`}
                  options={tickets}
                  defaultValue={
                    tickets && tickets?.length > 0 && tickets[0].value
                  }
                  w="206px"
                />
              </>
            )}

            <Text fontWeight="400" fontSize="16px" mt="8px" color="#344767">
              Amount
            </Text>
            <FormInput
              name={`finalPrizes[${indexItem}].rewards.${indexSub}.amount`}
              w="200px"
              onWheel={() => undefined}
              min={0}
              type="number"
              step="0.01"
            />
            <Icon
              fontSize="26px"
              cursor="pointer"
              mt="8px"
              onClick={() => {
                removeReward(indexSub);
              }}
              color={textColor}
              key={"add"}
              as={MdClose}
            />
          </>
        )}
      </Flex>
    );
  };

  const ListItemReward = ({ indexItem }) => {
    const {
      fields: fieldRewards,
      append: appendRewards,
      remove: removeReward,
    } = useSubItemFields(indexItem);

    return (
      <Flex key={item?.id} gap={4} direction="column">
        <Flex gap={4} align="center">
          <Text fontWeight="400" fontSize="16px" color="#344767">
            Rewards
          </Text>
          <Icon
            fontSize="26px"
            cursor="pointer"
            onClick={() => {
              appendRewards(defaultDataReward);
            }}
            color={textColor}
            key={"add"}
            as={MdOutlineAdd}
          />
        </Flex>
        {fieldRewards?.map((item, index) => {
          return (
            <ItemReward
              key={item?.id}
              indexSub={index}
              removeReward={removeReward}
            />
          );
        })}
      </Flex>
    );
  };

  const messageCheckTime = useMemo(() => {
    if (errorsFinal && errorsFinal[indexItem]) {
      return errorsFinal[indexItem]?.message;
    }
    return null;
  }, [errorsFinal, indexItem]);

  return (
    <Box
      position="relative"
      border="1px solid #EEEFF2"
      borderRadius="3px"
      p="22px 36px 36px 36px"
    >
      <Icon
        fontSize="26px"
        cursor="pointer"
        position="absolute"
        top="2px"
        right="2px"
        onClick={() => {
          removeFinalPrize(indexItem);
        }}
        color={textColor}
        key={"add"}
        as={MdClose}
      />
      <Flex align="flex-start" direction="column" gap={4}>
        <Flex direction="column" gap={4}>
          <Text fontWeight="400" fontSize="16px" color="#344767">
            Ranking
          </Text>
          <Flex align="flex-start" gap={4} ml={8}>
            <Text fontWeight="400" fontSize="16px" mt="12px" color="#344767">
              From
            </Text>
            <FormInput
              type="number"
              min={1}
              onWheel={() => undefined}
              name={`finalPrizes.${indexItem}.ranking_from`}
              w="100px"
            />
            <Text fontWeight="400" fontSize="16px" mt="12px" color="#344767">
              To
            </Text>
            <FormInput
              type="number"
              min={1}
              onWheel={() => undefined}
              name={`finalPrizes.${indexItem}.ranking_to`}
              w="100px"
            />
          </Flex>
        </Flex>
        {messageCheckTime && (
          <span
            style={{ color: "#E53E3E", fontWeight: "400", fontSize: "14px" }}
          >
            {messageCheckTime}
          </span>
        )}
        <ListItemReward indexItem={indexItem} />
      </Flex>
    </Box>
  );
}
