/* eslint-disable no-fallthrough */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addPlayFeeApi, editPlayFeeByIdApi } from "stores/playFee/action";
import { getOnlyDecimal } from "utils/number";
import schemaFee from "./schema";
import FormSelect from "components/form/FormSelect";
import { UMIT_OPTIONS } from "constants/constants";
import ButtonType from "components/Button";

export default function PlayFeeCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  handleViewClose,
}) {
  const textColor = useColorModeValue("#344767", "white");
  const { t } = useTranslation("playFee");
  const dispatch = useDispatch();

  const form = useForm({
    resolver: yupResolver(schemaFee()),
    defaultValues: dataDetail,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const handleClose = () => {
    reset();
    handleViewClose();
    onClose();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if (idEdit) {
      const request = {
        ...data,
        system_ticket_id: "TICKET_50C",
      };
      await dispatch(editPlayFeeByIdApi({ ...request }));
    } else {
      const request = {
        ...data,
        system_ticket_id: "TICKET_50C",
      };
      await dispatch(addPlayFeeApi({ ...request }));
    }
    reset();
    handleCreateOrUpdateSuccess();
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormSelect
              label="Unit"
              name="system_coin"
              defaultValue={
                dataDetail?.system_coin
                  ? dataDetail?.system_coin
                  : UMIT_OPTIONS[0].value
              }
              options={UMIT_OPTIONS}
              isRequired
            />
            <FormInput
              label={t("value_input")}
              name="value"
              maxLength={30}
              onChange={(e) => {
                setValue("value", getOnlyDecimal(e.target.value));
              }}
              isRequired
            />
            <FormInput
              label={t("exchange_rate_input")}
              name="cp_exchange_rate"
              maxLength={30}
              onChange={(e) => {
                setValue("cp_exchange_rate", getOnlyDecimal(e.target.value));
              }}
              isRequired
            />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px">
        <ModalHeader margin="auto" color={textColor}>
          {idEdit ? t("edit_play_fee_title") : t("create_play_fee_title")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderForm()}</ModalBody>
        <ModalFooter margin="auto">
          <ButtonType
            mt={4}
            type="submit"
            form="machine-form"
            m="auto"
            w="120px"
            h="46px"
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          >
            {t("btn_submit")}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
