import moment from "moment";
import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const rewardItemSchema = yup.object().shape({
  item: yup.string().required("Required"),
  type: yup.string().when("item", (item, schema) => {
    item === "TICKET" ? schema.required("Required") : schema;
  }),
  amount: yup
    .number()
    .nullable(true)
    .transform((_, val) => {
      if (val) {
        return Number(val) || Number(val) === 0 ? Number(val) : null;
      }
      return null;
    })
    .required("Required")
    .positive("Amount must be greater than 0"),
});

const rewardSchema = yup
  .object()
  .shape({
    ranking_from: yup
      .number()
      .nullable(true)
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .required("Required")
      .positive("Ranking from must be greater than 0"),
    ranking_to: yup
      .number()
      .nullable(true)
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .required("Required")
      .positive("Ranking to be greater than 0")
      .min(
        yup.ref("ranking_from"),
        "Ranking to must be greater than or equal to ranking from"
      ),

    rewards: yup.array().of(rewardItemSchema),
  })
  .test(
    "is-from-after-previous-to",
    "Ranking from must be after previous Ranking to",
    function (value) {
      if (this.path === "finalPrizes[0]") {
        return true;
      }
      const indexString = this.path.split("[")[1].split("]")[0];
      const index = Number(indexString);
      for (let i = 0; i < index; i++) {
        const previousTo = this.parent[i].ranking_to;
        const currentFrom = value.ranking_from;
        if (previousTo >= currentFrom) {
          return false;
        }
      }
      return true;
    }
  );

const schemaCreateEvent = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("Required")
      .max(30, "Name must be at most 30 characters")
      .matches(containSpecialRegex, "Name must not contain special characters"),
    description: yup
      .string()
      .trim()
      .notRequired()
      .max(300, "Description must be at most 300 characters"),
    rule_id: yup.string().trim().required("Required"),
    pre_register_start_time: yup
      .date()
      .test(
        "is-future-minute",
        "Pre-register end time must be in the future",
        function (value) {
          const now = new Date();
          now.setSeconds(0, 0); // Ignoring seconds
          const inputTime = new Date(value);
          inputTime.setSeconds(0, 0); // Ignoring seconds
          return inputTime.getTime() >= now.getTime();
        }
      )
      .required(),
    pre_register_end_time: yup
      .date()
      // .min(new Date(), "Pre-register end time must be in the future")
      .when("pre_register_start_time", (preRegisterStartTime, schema) =>
        preRegisterStartTime
          ? schema.min(
              preRegisterStartTime,
              "Pre Register end time must be after pre register start time"
            )
          : schema
      )
      .required(),
    register_end_time: yup
      .date()
      .when("pre_register_end_time", (preRegisterEndTime, schema) =>
        preRegisterEndTime
          ? schema.min(
              preRegisterEndTime,
              "Register end time must be after pre register end time"
            )
          : schema
      )
      .required(),
    whitelist_end_time: yup
      .date()
      .when("register_end_time", (registerEndTime, schema) =>
        registerEndTime
          ? schema.min(
              registerEndTime,
              "Whitelist end time must be after register end time"
            )
          : schema
      )
      .required(),
    event_end_time: yup
      .date()
      .when("whitelist_end_time", (whitelistEndTime, schema) =>
        whitelistEndTime
          ? schema.min(
              whitelistEndTime,
              "Event end time must be after whitelist end time"
            )
          : schema
      )
      .required(),
    result_end_time: yup
      .date()
      .when("event_end_time", (eventEndTime, schema) =>
        eventEndTime
          ? schema.min(
              eventEndTime,
              "Result end time must be after event end time"
            )
          : schema
      )
      .required(),
    finalPrizes: yup.array().of(rewardSchema),
  });

export default schemaCreateEvent;
