// Chakra imports
import { Flex, Icon, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import moment from "moment";
import { useMemo } from "react";
import { MdEdit } from "react-icons/md";
import { splitEclipseCharacter } from "utils/string";

const MachineRowGamePlay = ({
  machine,
  checkedIds,
  setCheckedIds,
  handleOpenCreateOrUpdate,
}) => {
  const textColor = useColorModeValue("#344767", "white");

  const isMachineChecked = useMemo(() => {
    return checkedIds.some((item) => item?.id === machine?.id);
  }, [machine?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedIds(checkedIds.filter((item) => item?.id !== machine?.id));
    } else {
      setCheckedIds([...checkedIds, machine]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {splitEclipseCharacter(machine?.name)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {moment(machine?.update_time * 1000)
            .utc()
            .format("DD MMM YYYY HH:mm:ss")}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {machine?.update_by || "Admin"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {moment(machine?.create_time * 1000)
            .utc()
            .format("DD MMM YYYY HH:mm:ss")}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {machine?.create_by || "Admin"}
        </Text>
      </Td>
      <Td>
        <Flex gap={6}>
          <Icon
            onClick={() => handleOpenCreateOrUpdate()}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={MdEdit}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default MachineRowGamePlay;
