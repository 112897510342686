import {
  Center,
  Flex,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createNewEvent,
  editEvent,
  getDetailEventManagement,
  getRulesEvent,
} from "api/event.api";
import { getSystemTicket } from "api/system";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import FormLabelComponent from "components/form/FormLabel";
import FormSelect from "components/form/FormSelect";
import FormTextAria from "components/form/FormTextAria";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { MdOutlineAdd } from "react-icons/md";
import { OPTIONS_REWARDS } from "views/Event/constant";
import {
  convertToIsoString,
  convertToLocal,
  dateAfterNow,
} from "views/Event/help";
import BoxFinalPrize from "./BoxFinalPrize";
import ItemEventPrize from "./ItemEventPrize";
import SelectDateCreateEvent from "./SelectDate";
import SelectPrize from "./SelectPrize";
import schemaCreateEvent from "./schema";

export default function CreateEvent({
  isOpen,
  onClose,
  dataDetail,
  handleUpdateSuccess,
}) {
  const [rules, setRules] = useState([]);
  const [detailEvent, setDetailEvent] = useState();
  const [tickets, setTickets] = useState([]);
  const textColor = useColorModeValue("#344767", "white");
  const {
    isOpen: isOpenSelectPrize,
    onOpen: onOpenSelectPrize,
    onClose: onCloseSelectPrize,
  } = useDisclosure();

  const toast = useToast();

  const defaultDataReward = {
    item: OPTIONS_REWARDS[0].value,
    amount: null,
  };
  const defaultDataFinalPrize = {
    ranking_from: null,
    ranking_to: null,
    rewards: [defaultDataReward],
  };
  const form = useForm({
    resolver: yupResolver(schemaCreateEvent()),
    defaultValues: {
      eventPrizes: [],
      finalPrizes: [],
    },
  });

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { isSubmitting, errors },
  } = form;

  const {
    fields: fieldEventPrizes,
    append: appendEventPrize,
    remove: removeEventPrize,
  } = useFieldArray({
    control,
    name: "eventPrizes",
  });

  const {
    fields: fieldFinalPrizes,
    append: appendFinalPrize,
    remove: removeFinalPrize,
  } = useFieldArray({
    control,
    name: "finalPrizes",
  });

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const getRules = async () => {
    try {
      const { data } = await getRulesEvent({
        page: 1,
        limit: 999999,
      });
      if (data?.success) {
        let rulesEvent = [];
        if (data?.data?.records && data?.data?.records?.length > 0) {
          rulesEvent = data?.data?.records?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }));
        }
        setRules(rulesEvent);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const getDetailEvent = useCallback(async () => {
    try {
      if (idEdit) {
        const { data } = await getDetailEventManagement(idEdit);
        if (data?.success) {
          const event = data?.data;
          setDetailEvent(event);
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }, [idEdit]);

  useEffect(() => {
    getRules();
  }, []);

  useEffect(() => {
    getDetailEvent();
  }, [getDetailEvent]);

  const getTicket = async () => {
    try {
      const { data } = await getSystemTicket({
        limit: 999999,
      });
      if (data?.success) {
        const list = data?.data?.records?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setTickets(list);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getTicket();
  }, []);

  useEffect(() => {
    if (detailEvent?.id) {
      let eventPrizes = [];
      let finalPrizes = [];
      if (detailEvent?.event_prizes && detailEvent?.event_prizes?.length > 0) {
        eventPrizes = detailEvent?.event_prizes?.map((item) => {
          return {
            event_id: item?.event_id,
            ...item?.prize,
          };
        });
      }
      if (
        detailEvent?.event_rewards &&
        detailEvent?.event_rewards?.length > 0
      ) {
        finalPrizes = detailEvent?.event_rewards?.map((item) => {
          let itemReward = [];
          if (
            item?.reward?.reward_items &&
            item?.reward?.reward_items?.length > 0
          ) {
            itemReward = item?.reward?.reward_items?.map((itemReward) => {
              if (itemReward?.type === "TICKET") {
                return {
                  idReward: item?.reward?.id,
                  idItem: itemReward?.id,
                  item: itemReward?.type,
                  type: itemReward?.item_type_id,
                  amount: itemReward?.value,
                };
              }
              if (itemReward?.type?.includes("COIN")) {
                return {
                  idReward: item?.reward?.id,
                  idItem: itemReward?.id,
                  item: itemReward?.item_type_id,
                  type: itemReward?.item_type_id,
                  amount: itemReward?.value,
                };
              }
            });
          }
          return {
            id: item?.id,
            ranking_from: item?.ranking_from,
            ranking_to: item?.ranking_to,
            rewards: itemReward,
          };
        });
      }
      reset({
        pre_register_start_time: detailEvent?.pre_register_start_time
          ? convertToLocal(detailEvent?.pre_register_start_time)
          : dateAfterNow(0),
        pre_register_end_time: detailEvent?.pre_register_end_time
          ? convertToLocal(detailEvent?.pre_register_end_time)
          : dateAfterNow(1),
        register_start_time: detailEvent?.register_start_time
          ? convertToLocal(detailEvent?.register_start_time)
          : dateAfterNow(1),
        register_end_time: detailEvent?.register_end_time
          ? convertToLocal(detailEvent?.register_end_time)
          : dateAfterNow(2),
        whitelist_start_time: detailEvent?.whitelist_start_time
          ? convertToLocal(detailEvent?.whitelist_start_time)
          : dateAfterNow(2),
        whitelist_end_time: detailEvent?.whitelist_end_time
          ? convertToLocal(detailEvent?.whitelist_end_time)
          : dateAfterNow(3),
        event_start_time: detailEvent?.event_start_time
          ? convertToLocal(detailEvent?.event_start_time)
          : dateAfterNow(3),
        event_end_time: detailEvent?.event_end_time
          ? convertToLocal(detailEvent?.event_end_time)
          : dateAfterNow(4),
        result_start_time: detailEvent?.result_start_time
          ? convertToLocal(detailEvent?.result_start_time)
          : dateAfterNow(4),
        result_end_time: detailEvent?.result_end_time
          ? convertToLocal(detailEvent?.result_end_time)
          : dateAfterNow(5),
        name: detailEvent?.name,
        description: detailEvent?.description,
        eventPrizes,
        finalPrizes,
        rule_id: detailEvent?.rule_id,
        status: detailEvent?.status,
        ranking_from_to: "ranking",
      });
    } else {
      reset({
        pre_register_start_time: dateAfterNow(0),
        pre_register_end_time: dateAfterNow(1),
        register_start_time: dateAfterNow(1),
        register_end_time: dateAfterNow(2),
        whitelist_start_time: dateAfterNow(2),
        whitelist_end_time: dateAfterNow(3),
        event_start_time: dateAfterNow(3),
        event_end_time: dateAfterNow(4),
        result_start_time: dateAfterNow(4),
        result_end_time: dateAfterNow(5),
        eventPrizes: [],
        finalPrizes: [],
        rule_id: rules[0] ? rules[0].value : "",
        ranking_from_to: "ranking",
        status: "OPEN",
      });
    }
  }, [reset, rules, detailEvent]);
  const onSubmit = async (data) => {
    let event_prizes = [];
    let event_rewards = [];
    if (data?.eventPrizes?.length > 0) {
      event_prizes = data?.eventPrizes?.map((item) => ({ prize_id: item?.id }));
    }
    if (data?.finalPrizes?.length > 0) {
      event_rewards = data?.finalPrizes?.map((item) => {
        let reward_items = [];
        if (item?.rewards && item?.rewards?.length > 0) {
          reward_items = item?.rewards?.map((itemReward) => {
            if (itemReward?.item?.includes("COIN")) {
              return {
                idReward: itemReward?.idReward,
                id: itemReward?.idItem,
                type: "COIN",
                item_type_id: itemReward?.item,
                value: itemReward?.amount,
              };
            } else if (itemReward?.item?.includes("TICKET")) {
              return {
                idReward: itemReward?.idReward,
                id: itemReward?.idItem,
                type: "TICKET",
                item_type_id: itemReward?.type,
                value: itemReward?.amount,
              };
            }
          });
        }
        return {
          id: item?.id,
          ranking_from: item?.ranking_from,
          ranking_to: item?.ranking_to,
          reward: {
            name: `Event Reward - Top ${
              item?.ranking_from === item?.ranking_to
                ? item?.ranking_from
                : `${item?.ranking_from} - ${item?.ranking_to}`
            }`,
            id: reward_items?.length > 0 && reward_items[0].idReward,
            type: "EVENT",
            reward_items,
          },
        };
      });
    }
    const dataSubmit = {
      pre_register_start_time: convertToIsoString(
        data?.pre_register_start_time
      ),
      pre_register_end_time: convertToIsoString(data?.pre_register_end_time),
      register_start_time: convertToIsoString(data?.register_start_time),
      register_end_time: convertToIsoString(data?.register_end_time),
      whitelist_start_time: convertToIsoString(data?.whitelist_start_time),
      whitelist_end_time: convertToIsoString(data?.whitelist_end_time),
      event_start_time: convertToIsoString(data?.event_start_time),
      event_end_time: convertToIsoString(data?.event_end_time),
      result_start_time: convertToIsoString(data?.result_start_time),
      result_end_time: convertToIsoString(data?.result_end_time),
      name: data?.name,
      description: data?.description,
      rule_id: data?.rule_id,
      event_prizes,
      event_rewards,
    };

    try {
      if (idEdit) {
        const { data } = await editEvent(idEdit, dataSubmit);
        if (data?.success) {
          toast({
            title: "Event updated.",
            description: "Event updated.",
            status: "success",
            duration: 3000,
            position: "top",
          });
          handleUpdateSuccess();
        }
      } else {
        const { data } = await createNewEvent(dataSubmit);
        if (data?.success) {
          toast({
            title: "Event created.",
            description: "Event created.",
            status: "success",
            duration: 3000,
            position: "top",
          });
          handleUpdateSuccess();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderEventPrize = () => {
    return (
      <Flex direction="column">
        <FormLabelComponent mb="22px" color={textColor} title="Event Prize" />
        <Center w={{ base: "100%", md: "72%" }} m="auto">
          <Flex direction="column" w="100%" gap={4} align="center">
            {fieldEventPrizes &&
              fieldEventPrizes?.length > 0 &&
              fieldEventPrizes?.map((item, index) => {
                return (
                  <ItemEventPrize
                    key={item?.id}
                    item={item}
                    indexItem={index}
                    removeEventPrize={removeEventPrize}
                  />
                );
              })}
          </Flex>
        </Center>
        <Center w="100%" mt="20px">
          <Icon
            fontSize="26px"
            cursor="pointer"
            onClick={() => {
              onOpenSelectPrize();
            }}
            color={textColor}
            key={"add"}
            as={MdOutlineAdd}
          />
        </Center>
      </Flex>
    );
  };

  const renderFinalPrize = useCallback(() => {
    return (
      <Flex direction="column">
        <Flex>
          <FormLabelComponent mb="22px" color={textColor} title="Final Prize" />
          <Icon
            fontSize="26px"
            cursor="pointer"
            onClick={() => {
              appendFinalPrize(defaultDataFinalPrize);
            }}
            color={textColor}
            key={"add"}
            as={MdOutlineAdd}
          />
        </Flex>
        <Flex direction="column" gap={4}>
          {fieldFinalPrizes?.map((item, index) => {
            return (
              <BoxFinalPrize
                key={item?.id}
                item={item}
                errorsFinal={errors["finalPrizes"]}
                indexItem={index}
                watch={watch}
                removeFinalPrize={removeFinalPrize}
                defaultDataReward={defaultDataReward}
                tickets={tickets}
              />
            );
          })}
        </Flex>
      </Flex>
    );
  }, [fieldFinalPrizes, errors["finalPrizes"]]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="create-event-form" onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" w="100%" gap={4}>
            <Grid templateColumns="repeat(2, 1fr)" rowGap={4} columnGap={16}>
              <Flex direction="column" gap={4}>
                <FormInput
                  maxW="315px"
                  name="name"
                  label="Event Name"
                  isRequired
                />
                <FormTextAria
                  label="Description"
                  name="description"
                  h="220px"
                />
                <FormSelect
                  label="Rule"
                  name="rule_id"
                  maxW="315px"
                  defaultValue={rules[0]?.value}
                  options={rules}
                  isRequired
                />
              </Flex>
              <SelectDateCreateEvent watch={watch} setValue={setValue} />
            </Grid>
            <hr
              style={{
                marginTop: "35px",
                marginBottom: "22px",
              }}
            />
            {renderEventPrize()}
            <hr
              style={{
                marginTop: "35px",
                marginBottom: "22px",
              }}
            />
            {renderFinalPrize()}
          </Flex>
        </form>
      </FormProvider>
    );
  };

  const checkDuplicateItem = (arr1, arr2) => {
    let idSet = new Set(arr2.map((item) => item.id));
    for (let item of arr1) {
      if (idSet.has(item?.id)) {
        return true;
      }
    }

    return false;
  };

  const onAddPrize = (listItem) => {
    if (listItem && listItem?.length > 0) {
      if (fieldEventPrizes && fieldEventPrizes?.length > 0) {
        const isDup = checkDuplicateItem(listItem, fieldEventPrizes);
        if (isDup) {
          toast({
            description: "Duplicate Item",
            status: "error",
            position: "bottom-right",
          });
          return;
        }
      }
      appendEventPrize(...listItem);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="1389px">
          <ModalHeader
            borderBottom="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
            color={textColor}
          >{`NEW EVENT`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="24px 80px">{renderForm()}</ModalBody>
          <ModalFooter
            borderTop="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
          >
            <ButtonType
              mt={4}
              type="submit"
              m="auto"
              w="120px"
              h="46px"
              borderRadius="5px"
              form="create-event-form"
              isLoading={isSubmitting}
              text="SAVE"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isOpenSelectPrize && (
        <SelectPrize
          isOpen={isOpenSelectPrize}
          onClose={onCloseSelectPrize}
          onAddPrize={onAddPrize}
        />
      )}
    </>
  );
}
