import { FormLabel, Text } from '@chakra-ui/react'

const FormLabelComponent = ({ title, isRequired = false, ...rest }) => {
  return (
    <FormLabel {...rest}>
      <Text display="inline-block" className='title' color="#344767">{title}</Text>
      {isRequired && <span style={{ color: 'red' }}>&nbsp;*</span>}
    </FormLabel>
  )
}

export default FormLabelComponent
