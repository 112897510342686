import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import moment from "moment";
import { useMemo } from "react";
import {
  GAME_MODE_OPTIONS,
  STATUS_HISTORY_PLAY_GAME_OPTIONS,
  STATUS_MODE,
} from "../../constant";

export default function HistoryPlayGameRow({ item, fontSize = "sm" }) {
  const textColor = useColorModeValue("#344767", "white");

  const isModeFree = useMemo(() => {
    return item?.room_history?.game_mode === STATUS_MODE.MODE_FREE;
  }, [item?.room_history?.game_mode]);
  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {item?.room_history?.game_mode &&
            GAME_MODE_OPTIONS?.find(
              (game) => game?.value === item?.room_history?.game_mode
            )?.label}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {!isModeFree && item?.user?.name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {item?.status &&
            STATUS_HISTORY_PLAY_GAME_OPTIONS?.find(
              (statusItem) => statusItem?.value === item?.status
            )?.label}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {moment(item?.room_history?.start_play_time * 1000)
            .utc()
            .format("DD MMM. YYYY HH:mm:ss")}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {moment(item?.update_time * 1000)
            .utc()
            .format("DD MMM. YYYY HH:mm:ss")}
        </Text>
      </Td>
    </Tr>
  );
}
