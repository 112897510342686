/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FormProvider } from 'react-hook-form'

import FormLabelComponent from 'components/form/FormLabel'
import { useSelector } from 'react-redux'
import ImageDefault from 'assets/img/empty-image.png'
import { projectCategorySelector } from 'stores/projectCategory/selector'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'

export default function ProjectCategoryDetail({ isOpen, onClose }) {
  const { t } = useTranslation('projectCategory')
  const textColor = useColorModeValue('#344767', 'white')
  const { projectCategoryById, loadingProjectCategory } = useSelector(projectCategorySelector)

  const renderForm = () => {
    return (
      <FormProvider>
        <Grid
          // templateRows='repeat(2, 1fr)'
          templateColumns='repeat(3, 1fr)'
          gap={4}
          mb={6}
        >
          <GridItem colSpan={1}>
            <FormLabelComponent mr={6} title='Background Image' />
            <Box
              w='150px'
              h='150px'
              backgroundSize='cover'
            >
                <Image src={projectCategoryById?.banner_image || ImageDefault} w='100%' h='100%' />
            </Box>
          </GridItem>
          <GridItem colSpan={2}>
            <Box>
              <FormLabelComponent mr={6} title={t('label_name')} />
              <Box color={textColor}>
                { projectCategoryById?.name || '---' }
              </Box>
            </Box>
            <Box mt={6}>
              <FormLabelComponent mr={6} title={t('label_description')} />
              <Box color={textColor}>
                { projectCategoryById?.description || '---' }
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </FormProvider>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent position='relative' minH='284px' maxW='700px'>
        <ModalHeader margin='auto' color={textColor}>{ `${projectCategoryById?.id} - ${projectCategoryById?.name}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loadingProjectCategory
            ? <Box position='absolute' top={0} left={0} w='100%' h='284px'>
                <LoadingSpinner />
              </Box>
          : renderForm()}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
