import { Flex } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import {
  GAME_MODE_OPTIONS,
  MACHINE_TYPE_OPTIONS,
  STATE_OPTIONS,
} from "constants/constants";
import { FormProvider, useForm } from "react-hook-form";
import { STATUS_OPTIONS } from "./constants";

export default function ConditionMachineMap({ isLoading, setParams, params }) {
  const form = useForm();

  const { setValue, handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      w="100%"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormInput
              w="349px"
              maxW="100%"
              name="keyword"
              label="Search"
              placeholder="Enter keywords"
            />
            <FormSelect
              name="status"
              label="Filter by Machine Status"
              defaultValue=""
              w="156px"
              options={[{ label: "All", value: "" }, ...STATUS_OPTIONS]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("status", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              name="state"
              label="Filter by Machine State"
              defaultValue={""}
              w="156px"
              options={[{ label: "All", value: "" }, ...STATE_OPTIONS]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("state", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              name="machine_type"
              label="Filter by Booth Type"
              defaultValue=""
              w="156px"
              options={[{ label: "All", value: "" }, ...MACHINE_TYPE_OPTIONS]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("machine_type", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            {/* <FormSelect
              name="game_mode"
              label="Filter by Game Mode"
              w="156px"
              options={[{ label: "All", value: "" }, ...GAME_MODE_OPTIONS]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("game_mode", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            /> */}
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
