import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ButtonType from "components/Button";

export default function ModalConfirm({
  isOpen,
  onClose,
  title = "Delete",
  description = "Do you want delete ?",
  textCancel = "Cancel",
  textOk = "OK",
  onSubmit,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{description}</ModalBody>

        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            onClick={onClose}
            mr={3}
            text={textCancel}
            btnType="dark"
          />
          <ButtonType mr={3} text={textOk} onClick={onSubmit} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
