import {
  Box,
  Center,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import { STATUS_USER_OPTIONS } from "views/Users/UserManagement/constant";
import PrizeHistoryTable from "../prize/table";
import { getHistoryPlayGameUser, getUserById } from "api/userManagement";
import { useCallback, useEffect, useState } from "react";
import HistoryPlayGameTable from "../historyPlay/table";

export default function UserDetailComponent({ isOpen, onClose, user }) {
  const [detailUser, setDetailUser] = useState();
  const [playHistories, setPlayHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const textColor = useColorModeValue("#344767", "white");
  const {
    isOpen: isOpenHistoryPlay,
    onOpen: onOpenHistoryPlay,
    onClose: onCloseHistoryPlay,
  } = useDisclosure();

  const getDetailUser = useCallback(async () => {
    try {
      if (user?.id) {
        setIsLoading(true);
        const [{ data: dataUser }, { data: dataHistory }] = await Promise.all([
          getUserById(user?.id),
          getHistoryPlayGameUser({
            user_id: user?.id,
            page: 1,
            limit: 999999,
          }),
        ]);
        if (dataUser?.success) {
          const user = dataUser?.data;
          setDetailUser(user);
        }
        if (dataHistory?.success) {
          const historiesPlay = dataHistory?.data?.records;
          setPlayHistories(historiesPlay);
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, [user?.id]);

  useEffect(() => {
    getDetailUser();
  }, [getDetailUser]);

  const handleClickShowFullHistoryPlayGame = () => {
    onOpenHistoryPlay(true);
  };

  const holderName = (item) => {
    const address = item?.public_address
      ? `_${item?.public_address?.slice(-2)}`
      : "";
    return `player_${item?.id?.slice(-4)}${address}`;
  };

  const renderInfoUser = () => {
    return (
      <Grid
        templateColumns="repeat(5, 1fr)"
        templateRows="repeat(3, 1fr)"
        gap={4}
        w="100%"
      >
        <GridItem>
          <Flex gap={4} align="center">
            <FormLabel
              fontWeight="700"
              fontSize="16px"
              m={0}
              w="60px"
              color={textColor}
            >
              {"Email"}
            </FormLabel>
            <Text fontWeight="400" fontSize="16px" color={textColor}>
              {detailUser?.email}
            </Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex gap={4} align="center">
            <FormLabel
              fontWeight="700"
              fontSize="16px"
              m={0}
              w="60px"
              color={textColor}
            >
              {"Wallet"}
            </FormLabel>
            <Text fontWeight="400" fontSize="16px" color={textColor}>
              {detailUser?.public_address}
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex gap={4} align="center">
            <FormLabel
              fontWeight="700"
              fontSize="16px"
              m={0}
              w="86px"
              color={textColor}
            >
              {"Status"}
            </FormLabel>
            <Text fontWeight="400" fontSize="16px" color={textColor}>
              {
                STATUS_USER_OPTIONS.find(
                  (item) => item.value === detailUser?.status
                )?.label
              }
            </Text>
          </Flex>
        </GridItem>
        <GridItem rowSpan={3} position="relative">
          <Flex
            gap={4}
            align="center"
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
          >
            <FormLabel
              fontWeight="700"
              fontSize="16px"
              m={0}
              w="30px"
              color={textColor}
            >
              {"XP"}
            </FormLabel>
            <Text fontWeight="400" fontSize="16px" color={textColor}>
              {detailUser?.xp}
            </Text>
          </Flex>
        </GridItem>
        <GridItem rowSpan={2}>
          <Flex gap={4} align="center">
            <FormLabel
              fontWeight="700"
              fontSize="16px"
              m={0}
              w="60px"
              color={textColor}
            >
              {"Name"}
            </FormLabel>
            <Text fontWeight="400" fontSize="16px" color={textColor}>
              {detailUser?.username || holderName(detailUser)}
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex gap={4} align="center">
            <FormLabel
              fontWeight="700"
              fontSize="16px"
              m={0}
              w="60px"
              color={textColor}
            >
              {"Title"}
            </FormLabel>
            <Text fontWeight="400" fontSize="16px" color={textColor}>
              {detailUser?.title}
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex gap={4} align="center">
            <FormLabel
              fontWeight="700"
              fontSize="16px"
              m={0}
              w="78px"
              color={textColor}
            >
              {"Skill rank"}
            </FormLabel>
            <Text fontWeight="400" fontSize="16px" color={textColor}>
              {detailUser?.ranking}
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex gap={4} align="center">
            <FormLabel
              fontWeight="700"
              fontSize="16px"
              m={0}
              w="86px"
              color={textColor}
            >
              {"Level"}
            </FormLabel>
            <Text fontWeight="400" fontSize="16px" color={textColor}>
              {detailUser?.level}
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex gap={4} align="center">
            <FormLabel
              fontWeight="700"
              fontSize="16px"
              m={0}
              w="60px"
              color={textColor}
            >
              {"CP"}
            </FormLabel>
            <Text fontWeight="400" fontSize="16px" color={textColor}>
              {detailUser?.user_contribution_point?.current_cp}
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex gap={4} align="center">
            <FormLabel
              fontWeight="700"
              fontSize="16px"
              m={0}
              w="78px"
              color={textColor}
            >
              {"AP"}
            </FormLabel>
            <Text fontWeight="400" fontSize="16px" color={textColor}>
              {detailUser?.user_attribute_point?.current_ap}
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex gap={4} align="center">
            <FormLabel
              fontWeight="700"
              fontSize="16px"
              m={0}
              w="86px"
              color={textColor}
            >
              {"Referrals"}
            </FormLabel>
            <Text fontWeight="400" fontSize="16px" color={textColor}>
              {detailUser?.referral}
            </Text>
          </Flex>
        </GridItem>
      </Grid>
    );
  };

  const renderBody = () => {
    return (
      <Flex p="40px 20px 60px 20px" w="100%" direction="column" gap={4}>
        {renderInfoUser()}
        <Flex gap={4} direction="column" w="100%">
          <Flex gap={4} direction="column">
            <Tabs variant="unstyled">
              <TabList gap={2}>
                <Tab
                  fontSize="16px"
                  fontWeight="700"
                  border="1px solid #EEEFF2"
                  boxShadow="none !important"
                  borderRadius="5px"
                  color={textColor}
                  _selected={{
                    color: "white",
                    backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                  }}
                >
                  Prize History
                </Tab>
                <Tab
                  fontSize="16px"
                  fontWeight="700"
                  border="1px solid #EEEFF2"
                  boxShadow="none !important"
                  borderRadius="5px"
                  color={textColor}
                  _selected={{
                    color: "white",
                    backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                  }}
                >
                  Play History
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  {detailUser?.prize && detailUser?.prize?.length > 0 ? (
                    <Box
                      w={{
                        base: "100%",
                        xl: "73%",
                      }}
                      m="auto"
                    >
                      <PrizeHistoryTable
                        isShowPagination={false}
                        isShowBtnShowFull
                        minH="auto"
                        maxW="100%"
                        fontSize="16px"
                      />{" "}
                    </Box>
                  ) : (
                    <Center fontWeight="400" fontSize="16px" color="#344767">
                      No data to display
                    </Center>
                  )}
                </TabPanel>
                <TabPanel>
                  <Box
                    w={{
                      base: "100%",
                      xl: "73%",
                    }}
                    m="auto"
                  >
                    <HistoryPlayGameTable
                      isShowPagination={false}
                      isLoading={isLoading}
                      data={playHistories?.slice(0, 5)}
                      textNoData="No data to display"
                      maxW="100%"
                      isShowBtnShowFull={playHistories?.length > 5}
                      handleClickShowFull={handleClickShowFullHistoryPlayGame}
                    />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Flex>
    );
  };
  return (
    <>
      {isOpenHistoryPlay && (
        <ModalBase
          maxWContent="1400px"
          isOpen={isOpenHistoryPlay}
          onClose={onCloseHistoryPlay}
          showBtn={false}
          titleHeader={`${user?.username || holderName(user)} - PLAY HISTORY`}
        >
          <HistoryPlayGameTable
            isShowPagination={false}
            isLoading={isLoading}
            data={playHistories}
            textNoData="No data to display"
            maxW="100%"
          />
        </ModalBase>
      )}
      <ModalBase
        maxWContent="1400px"
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader={user?.username || holderName(user)}
      >
        {renderBody()}
      </ModalBase>
    </>
  );
}
