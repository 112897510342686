import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createRuleEvent, editRuleEvent } from "api/event.api";
import FormInput from "components/form/FormInput";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaRuleEvent from "./schema";
import ButtonType from "components/Button";

export default function RuleEventCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const textColor = useColorModeValue('#344767', 'white')
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaRuleEvent()),
    defaultValues: {
      name: "",
      win_point: null,
      turn_duration: null,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset({
        name: dataDetail?.name,
        win_point:
          dataDetail?.rule_event_items &&
          dataDetail?.rule_event_items[0]?.value,
        turn_duration:
          dataDetail?.rule_event_items &&
          dataDetail?.rule_event_items[1]?.value,
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const onSubmit = async (data) => {
    const dataSubmit = {
      name: data?.name,
      rule_event_items: [
        {
          rule_event_type: "WIN_POINT",
          value: data?.win_point,
        },
        {
          rule_event_type: "TURN_DURATION",
          value: data?.turn_duration,
        },
      ],
    };
    try {
      if (idEdit) {
        const res = await editRuleEvent(idEdit, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Rule Event updated.",
            description: "Rule Event updated.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        }
      } else {
        const res = await createRuleEvent(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Rule Event created.",
            description: "Rule Event created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap={4}>
            <FormInput label="Event Rule Name" name="name" isRequired />
            <FormInput
              type="number"
              min={1}
              label="Win Point"
              name="win_point"
              isRequired
            />
            <FormInput
              type="number"
              min={1}
              label="Turn Duration"
              name="turn_duration"
              isRequired
            />
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="787px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "EDIT EVENT RULE" : "NEW EVENT RULE"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px 150px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            mt={4}
            btnType="primary"
            type="submit"
            m="auto"
            w="120px"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
