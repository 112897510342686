import { emailRegex, containSpecialRegex } from "utils/regex";
import * as yup from "yup";

export const schemaCreateUser = () =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required("Required")
      .max(30, "Email must be at most 30 characters")
      .matches(emailRegex, "Invalid email format"),
    public_address: yup.string().trim().required("Required"),
  });

export const schemaUpdateUser = () =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      // .required("Required")
      .max(30, "Email must be at most 30 characters")
      .matches(emailRegex, "Invalid email format"),
    public_address: yup.string().trim(),
    // .required("Required")
    username: yup
      .string()
      .trim()
      .max(30, "UserName must be at most 30 characters")
      .matches(
        containSpecialRegex,
        "UserName must not contain special characters"
      )
      .notRequired(),
    status: yup.string().trim().required("Required"),
    xp: yup
      .number()
      .nullable(true)
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("XP must be an integer"),
    contribute_point: yup
      .number()
      .nullable(true)
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("CP must be an integer"),
    attribute_point: yup
      .number()
      .nullable(true)
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("AP must be an integer"),
  });
