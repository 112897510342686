import { NUMBER, STRING } from 'constants/enum'

export function formatNumber(value) {
  value += ''
  const list = value.split('.')
  const prefix = list[0].charAt(0) === '-' ? '-' : ''
  let num = prefix ? list[0].slice(1) : list[0]
  let result = ''
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`
}

// 11.000 => 11
// 11.110 => 11.11
export function numberWithCommas(num) {
  let parts = num.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts[1] === '000'
    ? parts[0]
    : parts.join('.').replace(/(\.0*|(?<=(\..*))0*)$/, '')
}

// 11.0000000000 => 11
export function formatNumberAmount(num) {
  let parts = num.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts[1] === '0000000000'
    ? parts[0]
    : parts.join('.').replace(/(\.0*|(?<=(\..*))0*)$/, '')
}

export const formatNumberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const format5Decimals = (str) => {
  const num = parseFloat(str.toString())

  return Math.round(num * 100000) / 100000
}

export const takeDecimalNumber = (num, n) => {
  const base = 10 ** n
  const result = Math.round(num * base) / base
  return result
}

export const onlyNumberDecimal5 = (value) => {
  if (value?.split('.')?.length === 3) {
    return value?.substring(0, value?.length - 1)
  }
  value = value ? `${value}`.replace('..', '.').replace(/[^.0-9]/g, '') : ''
  const parts = value?.toString()?.split('.')
  if (parts[1]?.length > 5) {
    parts[1] = parts[1]?.slice(0, 5)
    value = parts?.join('.')?.replace(/(\.0*|(?<=(\..*))0*)$/, '')
  }
  return value
}

export function getOnlyDecimal(value, num) {
  value = onlyNumberDecimal5(value)
  if (
    +value === NUMBER.ZERO &&
    value?.length === NUMBER.TWO &&
    !value?.includes('.')
  ) {
    return NUMBER.ZERO
  } else if (+value > NUMBER.ZERO && value?.length === NUMBER.TWO) {
    switch (value?.toString()) {
      case STRING.STR_01: {
        return NUMBER.ONE
      }
      case STRING.STR_02: {
        return NUMBER.TWO
      }
      case STRING.STR_03: {
        return NUMBER.THREE
      }
      case STRING.STR_04: {
        return NUMBER.FOUR
      }
      case STRING.STR_05: {
        return NUMBER.FIVE
      }
      case STRING.STR_06: {
        return NUMBER.SIX
      }
      case STRING.STR_07: {
        return NUMBER.SEVEN
      }
      case STRING.STR_08: {
        return NUMBER.EIGHT
      }
      case STRING.STR_09: {
        return NUMBER.NINE
      }
      default:
        break
    }
  } else if (+value > num) {
    return (value = num)
  }
  return value
}

export function getOnlyNumber(num) {
  if (+num >= 0 && num?.length === 2) {
    switch (num?.toString()) {
      case STRING.STR_00: {
        return '0'
      }
      case STRING.STR_01: {
        return NUMBER.ONE
      }
      case STRING.STR_02: {
        return NUMBER.TWO
      }
      case STRING.STR_03: {
        return NUMBER.THREE
      }
      case STRING.STR_04: {
        return NUMBER.FOUR
      }
      case STRING.STR_05: {
        return NUMBER.FIVE
      }
      case STRING.STR_06: {
        return NUMBER.SIX
      }
      case STRING.STR_07: {
        return NUMBER.SEVEN
      }
      case STRING.STR_08: {
        return NUMBER.EIGHT
      }
      case STRING.STR_09: {
        return NUMBER.NINE
      }
      default:
        return num
    }
  }
  return num
}

// 1.23E+7 => 12300000
export const toPlainString = (num) => {
  return ('' + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + '0.' + Array(1 - e - c?.length)?.join('0') + c + d
        : b + c + d + Array(e - d?.length + 1)?.join('0')
    },
  )
}

export const onlyNumberStr = (value = '') => {
  return value
    ? `${value}`.replace(new RegExp("\\.", "gi"), "").replace(/[^0-9]/g, "")
    : "";
};

export const onlyNumber = (value = '') => {
  return value ? `${value}`.replace(/[^0-9]/g, "") : "";
}

export const onlyDecimal = (value = '') => {
  return value ? `${value}`.replace(/[^0-9.]/g, "") : "";
}
