import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ButtonType from "components/Button";

export default function DeleteModal({
  isOpen,
  onClose,
  handleDelete,
  title = "",
  content = "",
  btnYes = "",
  btnNo = "",
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          {btnNo && (
            <ButtonType btnType="dark" onClick={onClose} mr={3}>
              {btnNo}
            </ButtonType>
          )}
          {btnYes && (
            <ButtonType onClick={handleDelete}>
              {btnYes}
            </ButtonType>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
