import common from './common.json'
import auth from './auth.json'
import prize from './prize.json'
import user from './user.json'
import projectCategory from './projectCategory.json'
import playFee from './playFee.json'
import revenueSharing from './revenueSharing.json'
import mantenance from './mantenance.json'

export default {
  common,
  auth,
  prize,
  user,
  projectCategory,
  playFee,
  revenueSharing,
  mantenance,
}
