import {
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createGamePlayType, updateGamePlayType } from "api/gameplayType.api";
import FormInput from "components/form/FormInput";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaMachine from "./schema";
import ButtonType from "components/Button";

export default function MachineCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const textColor = useColorModeValue("#344767", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaMachine()),
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset({
        ...dataDetail,
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      name: data?.name,
    };
    try {
      if (idEdit) {
        const res = await updateGamePlayType(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Game Play updated.",
            description: "Game Play updated.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        }
      } else {
        const res = await createGamePlayType(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Game Play created.",
            description: "Game Play created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormInput label="Name" name="name" isRequired />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="552px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "EDIT GAMEPLAY TYPE" : "NEW GAMEPLAY TYPE"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="120px"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
