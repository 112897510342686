import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

export default function TableCustom({
  labels = [],
  dataRow = [],
  isLoading = false,
  isCheck = true,
  isChecked = false,
  onChangeChecked,
  children,
  textNoData = "NO DATA",
}) {
  return (
    <Box overflowX="auto" position="relative" w="100%">
      <Table variant="simple">
        <Thead>
          <Tr backgroundColor="#F9FAFB">
            {isCheck && (
              <Th width="10px">
                <CheckBoxType
                  colorScheme="teal"
                  isChecked={isChecked}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    onChangeChecked && onChangeChecked(isChecked);
                  }}
                ></CheckBoxType>
              </Th>
            )}
            {labels.map((label) => {
              return (
                <Th
                  whiteSpace="nowrap"
                  color="#8392ab"
                  fontWeight="700"
                  fontSize="sm"
                  textTransform="capitalize"
                  key={label}
                >
                  {label}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {dataRow.length > 0 ? (
            children
          ) : (
            <Tr>
              <Td colSpan={labels.length + 1}>
                <Text textAlign="center" color="#344767" padding="80px 0px">
                  {isLoading ? (
                    <Box position="absolute" top={0} left={0} w="100%" h="100%">
                      <LoadingSpinner />
                    </Box>
                  ) : (
                    textNoData
                  )}
                </Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {dataRow.length > 0 && isLoading ? (
        <Box position="absolute" top={0} left={0} w="100%" h="100%">
          <LoadingSpinner />
        </Box>
      ) : null}
    </Box>
  );
}
