/* eslint-disable no-useless-catch */
import { createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'
import {
  ADD_PLAY_FEE_FETCH,
  DELETE_PLAY_FEE_BY_ID_FETCH,
  EDIT_PLAY_FEE_BY_ID_FETCH,
  GET_PLAY_FEE_BY_ID_FETCH,
  GET_PLAY_FEE_FETCH,
  PLAY_FEE_NAMESPACE,
} from '../constant'
import { deletePlayFee, getPlayFees, getPlayFeesById, postPlayFees, putPlayFee } from 'api/playFee.api'
import { createStandaloneToast } from '@chakra-ui/react'
import { convertParams } from 'utils/object'

const toast = createStandaloneToast()

export const getPlayFeeApi = createAsyncThunk(
  `${PLAY_FEE_NAMESPACE}/${GET_PLAY_FEE_FETCH}`,
  async (request) => {
    try {
      const params = convertParams(request)
      const res = await getPlayFees(params)
      if (res.data.code === 200) {
        return res?.data?.data
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      // if (error?.message || error?.messages[0]) {
      //   toast({
      //     description: error?.message || error?.messages[0],
      //     status: 'error',
      //     position: 'bottom-right',
      //   })
      // }
      throw error
    }
  },
)

export const addPlayFeeApi = createAsyncThunk(
  `${PLAY_FEE_NAMESPACE}/${ADD_PLAY_FEE_FETCH}`,
  async (data) => {
    try {
      const res = await postPlayFees(data)
      if (res?.status === 200) {
        toast({
          description: i18next.t('playFee:request_add_play_fee'),
          status: 'success',
          position: 'bottom-right',
        })
        return res?.data
      } else {
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      if (error?.message || error?.messages[0]) {
        toast({
          description: error?.message || error?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
      throw error
    }
  },
)

export const getPlayFeeByIdApi = createAsyncThunk(
  `${PLAY_FEE_NAMESPACE}/${GET_PLAY_FEE_BY_ID_FETCH}`,
  async (params) => {
    try {
      const res = await getPlayFeesById(params)
      if (res?.status === 200) {
        return res?.data
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      // if (error?.message || error?.messages[0]) {
      //   toast({
      //     description: error?.message || error?.messages[0],
      //     status: 'error',
      //     position: 'bottom-right',
      //   })
      // }
      throw error
    }
  },
)

export const editPlayFeeByIdApi = createAsyncThunk(
  `${PLAY_FEE_NAMESPACE}/${EDIT_PLAY_FEE_BY_ID_FETCH}`,
  async (data) => {
    try {
      const res = await putPlayFee(data)
      if (res?.status === 200) {
        toast({
          description: i18next.t('playFee:request_edit_play_fee'),
          status: 'success',
          position: 'bottom-right',
        })
        return res?.data
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      // if (error?.message || error?.messages[0]) {
      //   toast({
      //     description: error?.message || error?.messages[0],
      //     status: 'error',
      //     position: 'bottom-right',
      //   })
      // }
      throw error
    }
  },
)

export const deletePlayFeeByIdApi = createAsyncThunk(
  `${PLAY_FEE_NAMESPACE}/${DELETE_PLAY_FEE_BY_ID_FETCH}`,
  async (params) => {
    try {
      await params?.map(async (param) => {
        await deletePlayFee(param)
        // if (res?.status !== 200) {
        //   toast({
        //     description: res?.data?.message || res?.data?.messages[0],
        //     status: 'error',
        //     position: 'bottom-right',
        //   })
        // }
      })
      toast({
        description: i18next.t('playFee:request_delete_play_fee'),
        status: 'success',
        position: 'bottom-right',
      })
    } catch (error) {
      // if (error?.message || error?.messages[0]) {
      //   toast({
      //     description: error?.message || error?.messages[0],
      //     status: 'error',
      //     position: 'bottom-right',
      //   })
      // }
      throw error
    }
  },
)
