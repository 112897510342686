import {
  Button,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUser } from "api/userManagement";
import FormInput from "components/form/FormInput";
import FormLabelComponent from "components/form/FormLabel";
import FormSelect from "components/form/FormSelect";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { STATUS_USER_OPTIONS } from "../constant";
import { schemaUpdateUser } from "./schema";
import ButtonType from "components/Button";

export default function EditUser({
  isOpen,
  onClose,
  user,
  handleUpdateSuccess,
}) {
  const textColor = useColorModeValue("#344767", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaUpdateUser()),
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form;

  const holderName = (id, public_address) => {
    const address = public_address ? `_${public_address?.slice(-2)}` : "";
    return `player_${id?.slice(-4)}${address}`;
  };

  const onSubmit = async (value) => {
    try {
      const dataUpdate = {
        ...value,
        username:
          value?.username || holderName(user?.id, value?.public_address),
        contribute_point: value?.contribute_point
          ? Number(value?.contribute_point)
          : 0,
        attribute_point: value?.attribute_point
          ? Number(value?.attribute_point)
          : 0,
      };
      const { data } = await updateUser(user?.id, dataUpdate);
      if (data?.success) {
        toast({
          title: "User updated.",
          description: "User updated.",
          status: "success",
          duration: 3000,
          position: "top",
        });
        handleUpdateSuccess();
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (user) {
      reset({
        email: user?.email,
        public_address: user?.public_address,
        username: user?.username || holderName(user?.id, user?.public_address),
        status: user?.status,
        // xp: user?.user_contribution_point?.current_cp,
        contribute_point: user?.user_contribution_point?.current_cp,
        attribute_point: user?.user_attribute_point?.current_ap,
      });
    }
  }, [user]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="edit-user-form" onSubmit={handleSubmit(onSubmit)}>
          <Flex gap={4} direction="column">
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <Flex
                alignItems="flex-start"
                direction="column"
                gap={4}
                align="center"
              >
                <FormLabelComponent
                  title="Email"
                  isRequired
                  fontWeight="700"
                  fontSize="16px"
                  m={0}
                  w="60px"
                />
                <Text
                  ml={4}
                  fontWeight="400"
                  fontSize="16px"
                  maxW="360px"
                  color={textColor}
                >
                  {user?.email}
                </Text>
              </Flex>
              <Flex
                alignItems="flex-start"
                direction="column"
                gap={4}
                align="center"
                w="100%"
              >
                <FormLabelComponent
                  title="Wallet"
                  isRequired
                  fontWeight="700"
                  fontSize="16px"
                  m={0}
                  w="60px"
                />
                <Text
                  ml={4}
                  fontWeight="400"
                  fontSize="16px"
                  maxW="370px"
                  color={textColor}
                >
                  {user?.public_address}
                </Text>
              </Flex>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <FormInput label="Name" name="username" />
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <FormSelect
                label="Status"
                defaultValue={STATUS_USER_OPTIONS[0].value}
                name="status"
                options={STATUS_USER_OPTIONS}
              />
            </Grid>
            <Grid templateColumns="repeat(3, 1fr)" gap={4}>
              <FormInput type="number" label="XP" name="xp" />
              <FormInput type="number" label="CP" name="contribute_point" />
              <FormInput type="number" label="AP" name="attribute_point" />
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="900px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {"EDIT USER"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            text="SAVE"
            mt={4}
            m="auto"
            w="120px"
            h="46px"
            borderRadius="5px"
            type="submit"
            form="edit-user-form"
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
