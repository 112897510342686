/* eslint-disable no-useless-catch */
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  ADD_PRIZES_FETCH,
  DELETE_PRIZE_ALL_FETCH,
  DELETE_PRIZE_BY_ID_FETCH,
  EDIT_PRIZE_BY_ID_FETCH,
  GET_PRIZE_BY_ID_FETCH,
  LIST_PRIZES_FETCH,
  LIST_PRIZE_TYPE_FETCH,
  PRIZE_NAMESPACE,
} from '../constant'
import {
  deletePrize,
  deletePrizeAll,
  getPrize,
  getPrizeById,
  postPrize,
  putPrize,
  typePrize,
} from 'api/prize.api'
// import { useToastCus } from 'utils/useToast'
import i18next from 'i18next'
import { createStandaloneToast } from '@chakra-ui/react'
import { convertParams } from 'utils/object'

const toast = createStandaloneToast()

export const listPrizesApi = createAsyncThunk(
  `${PRIZE_NAMESPACE}/${LIST_PRIZES_FETCH}`,
  async (request) => {
    try {
      const params = convertParams(request)
      const res = await getPrize(params)
      // useToastCus({ status: 'success', title: 'Toast' })
      if (res.data.code === 200) {
        return res?.data
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      if (error?.message || error?.messages[0]) {
        toast({
          description: error?.message || error?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
      throw error
    }
  },
)

export const addPrizeApi = createAsyncThunk(
  `${PRIZE_NAMESPACE}/${ADD_PRIZES_FETCH}`,
  async (data) => {
    try {
      const res = await postPrize(data)
      if (res?.status === 200) {
        toast({
          description: i18next.t('prize:request_add_prize'),
          status: 'success',
          position: 'bottom-right',
        })
        return res?.data
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      if (error?.message || error?.messages[0]) {
        toast({
          description: error?.message || error?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
      throw error
    }
  },
)

export const getPrizeByIdApi = createAsyncThunk(
  `${PRIZE_NAMESPACE}/${GET_PRIZE_BY_ID_FETCH}`,
  async (params) => {
    try {
      const res = await getPrizeById(params)
      if (res?.status === 200) {
        return res?.data
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      if (error?.message || error?.messages[0]) {
        toast({
          description: error?.message || error?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
      throw error
    }
  },
)

export const editPrizeByIdApi = createAsyncThunk(
  `${PRIZE_NAMESPACE}/${EDIT_PRIZE_BY_ID_FETCH}`,
  async (data) => {
    try {
      const res = await putPrize(data)
      if (res?.status === 200) {
        toast({
          description: i18next.t('prize:request_edit_prize'),
          status: 'success',
          position: 'bottom-right',
        })
        return res?.data
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      if (error?.message || error?.messages[0]) {
        toast({
          description: error?.message || error?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
      throw error
    }
  },
)

export const deletePrizeByIdApi = createAsyncThunk(
  `${PRIZE_NAMESPACE}/${DELETE_PRIZE_BY_ID_FETCH}`,
  async (params) => {
    try {
      await params?.map(async (param) => {
        const res = await deletePrize(param)
        if (res?.status !== 200) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        } else {
          console.log("res ==========> ", res);
          if (res?.data?.message || res?.data?.messages[0]) {
            toast({
              description: res?.data?.message || res?.data?.messages[0],
              status: 'error',
              position: 'bottom-right',
            })
          }
        }
      })
    } catch (error) {
      if (error?.message || error?.messages[0]) {
        toast({
          description: error?.message || error?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
      throw error
    }
  },
)

export const deletePrizeAllApi = createAsyncThunk(
  `${PRIZE_NAMESPACE}/${DELETE_PRIZE_ALL_FETCH}`,
  async (params) => {
    try {
      const res = await deletePrizeAll(params)
      if (res?.status !== 200) {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      if (error?.message || error?.messages[0]) {
        toast({
          description: error?.message || error?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
      throw error
    }
  },
)

export const listPrizeTypeApi = createAsyncThunk(
  `${PRIZE_NAMESPACE}/${LIST_PRIZE_TYPE_FETCH}`,
  async () => {
    try {
      const res = await typePrize()
      if (res?.status === 200) {
        return res?.data
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      if (error?.message || error?.messages[0]) {
        toast({
          description: error?.message || error?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
      throw error
    }
  },
)
