/* eslint-disable react/prop-types */
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
} from '@chakra-ui/react'
import { useController, useFormContext } from 'react-hook-form'

const FormTextAria = ({
  label,
  name,
  rules,
  defaultValue = '',
  placeholder = '',
  wrapperProps,
  isRequired,
  size = 'sm',
  rows = 5,
  ...rest
}) => {
  const { control } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue })

  return (
    <FormControl
      maxW='100%'
      w='auto'
      isInvalid={error?.message}
      {...wrapperProps}
    >
      {label && (
        <FormLabel whiteSpace='nowrap' color="#344767">
          {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </FormLabel>
      )}

      <Textarea
        color="#344767"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        size={size}
        rows={rows}
        {...rest}
      />
      {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  )
}

export default FormTextAria
