export const OPTIONS_STATUS = [
  { label: "Terminated", value: "OPEN" },
  { label: "Pre-registration", value: "IN_PRE_REGISTER" },
  { label: "Registration", value: "IN_REGISTER" },
  { label: "Whitelist period", value: "IN_WHITELIST" },
  { label: "Results", value: "IN_RESULT" },
  { label: "In Event", value: "IN_EVENT" },
  { label: "Upcoming", value: "CLOSED" },
];

export const STATUS_ONGOING = [
  "IN_PRE_REGISTER",
  "IN_REGISTER",
  "IN_WHITELIST",
  "IN_RESULT",
  "IN_EVENT",
];

export const RULE_STATUS = [
  { label: "Alpha Test", value: "1776183789589991426" },
];

export const OPTIONS_REWARDS = [
  { label: "C_COIN_1", value: "C_COIN_1" },
  { label: "C_COIN_2", value: "C_COIN_2" },
  { label: "B_COIN", value: "B_COIN" },
  { label: "TICKET", value: "TICKET" },
  // { label: "NFT", value: "NFT" },
];
export const OPTIONS_TYPE = [
  { label: "50C Ticket", value: "50_C_COIN_1_TICKET" },
];
