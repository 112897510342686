/* eslint-disable no-undef */
import * as yup from 'yup'
import i18next from 'I18n'

const schemaFee = () =>
  yup.object().shape({
    payee: yup.string()
      .trim()
      .required(i18next.t('revenueSharing:payee_required'))
      .max(30, i18next.t('revenueSharing:payee_max')),
    payee_jp: yup.string()
      .trim()
      .required(i18next.t('revenueSharing:payee_required'))
      .max(30, i18next.t('revenueSharing:payee_max')),
    sharing_percentage: yup
      .string()
      .trim()
      .required(i18next.t('revenueSharing:sharing_percentage_required')),
  })

export default schemaFee
