import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";

export default function PrizeHistoryRow({ item, fontSize = "sm" }) {
  const textColor = useColorModeValue("#344767", "white");
  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {item?.prize?.name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {item?.prize?.prize_type}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {item?.prize?.play_fee?.value &&
            item?.prize?.play_fee?.system_coin &&
            `${item?.prize?.play_fee?.value} ${item?.prize?.play_fee?.system_coin}`}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {/* {item?.prize?.play_fee?.system_coin} */}
        </Text>
      </Td>
    </Tr>
  );
}
