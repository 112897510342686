export const STATUS_MODE = {
  MODE_ARENA_BATTLE: "MODE_ARENA_BATTLE",
  MODE_FREE: "MODE_FREE",
  MODE_GET_PRIZE: "MODE_GET_PRIZE",
};

export const STATUS_USER_OPTIONS = [
  { label: "Active", value: "ACTIVE" },
  { label: "Blocked", value: "BLOCKED" },
];

export const STATUS_USER = {
  BLOCKED: "BLOCKED",
  ACTIVE: "ACTIVE",
};

export const GAME_MODE_OPTIONS = [
  { label: "PvP", value: STATUS_MODE.MODE_ARENA_BATTLE },
  { label: "Free", value: STATUS_MODE.MODE_FREE },
  { label: "Get Prize", value: STATUS_MODE.MODE_GET_PRIZE },
];

export const STATUS_HISTORY_PLAY_GAME_OPTIONS = [
  { label: "Win", value: "WIN" },
  { label: "Lose", value: "LOSE" },
];
