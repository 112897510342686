/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Box,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrizeByIdApi,
  listPrizeTypeApi,
  listPrizesApi,
} from "stores/prize/action";
import { prizeSelector } from "stores/prize/selector";

import ConditionPrize from "./condition";
// import PrizeDetail from './components/detail'
import Paginate from "components/Paginate";
import NoResultsTableRow from "components/Tables/NoResultsTableRow";
import PrizeRow from "components/Tables/prize/PrizeRow";
import { ACTION_PAGE, FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
import { getGamePlayTypeApi } from "stores/gamePlay/action";
import { getPlayFeeApi } from "stores/playFee/action";
import PrizeCreateAndUpdate from "./components/createAndUpdate";
// import { PrizeIcon } from 'components/Icons/PrizeIcon'
import CheckBoxType from "components/Checkbox";
import { getCabinetTypeApi } from "stores/cabinetType/action";
import { getProjectCategoryApi } from "stores/projectCategory/action";
import { getRevenueSharingApi } from "stores/revenueSharing/action";
import PrizeDetail from "./components/detail";
import ActionPrizeSettings from "./action";

export default function PrizeSettings() {
  const { t } = useTranslation("prize");
  const dispatch = useDispatch();
  const textColor = useColorModeValue("#344767", "white");
  const [checkedIds, setCheckedIds] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const actionPageRef = useRef(ACTION_PAGE.VIEW);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });
  const { listPrizes, loadingPrize } = useSelector(prizeSelector);

  const [dataTable, setDataTable] = useState([]);
  const [total, setTotal] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const getListGamePlayType = () => {
    dispatch(getGamePlayTypeApi());
  };

  const getListPlayFee = () => {
    dispatch(getPlayFeeApi());
  };

  const getListProjectCategory = () => {
    dispatch(getProjectCategoryApi());
  };

  const getListPrizeType = () => {
    dispatch(listPrizeTypeApi());
  };

  const getCabinetType = () => {
    dispatch(getCabinetTypeApi());
  };

  const getRevenueSharing = () => {
    dispatch(getRevenueSharingApi());
  };

  useEffect(() => {
    getListPrizeType();
    getListGamePlayType();
    getListPlayFee();
    getListProjectCategory();
    getCabinetType();
    getRevenueSharing();
  }, []);

  const handleClose = () => {
    onClose();
    setDataDetail(null);
  };

  const handleDetailClose = () => {
    onCloseDetail();
  };

  const getListPrize = () => {
    dispatch(listPrizesApi(params));
  };

  useEffect(() => {
    getListPrize();
  }, [params]);

  useEffect(() => {
    if (listPrizes) {
      setDataTable(() => listPrizes?.records || []);
      setTotal(listPrizes?.total_page || 0);
    }
  }, [listPrizes]);

  const handleOpenCreateOrUpdate = (handle = ACTION_PAGE.VIEW, data = null) => {
    actionPageRef.current = handle;
    setDataDetail(data);
    onOpen();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: FIRST_PAGE,
    });
  }, [params]);

  const labels = [
    t("label_image"),
    t("label_name"),
    t("label_type"),
    t("label_dedicated_to"),
    t("label_play_fee"),
    t("label_estimate"),
    t("label_gameplay_type"),
    t("label_booth_type"),
    t("label_inv_count"),
    t("label_payee"),
    t("label_sharing"),
    t("label_bounty_ball"),
    t("label_last_modified_at"),
    t("label_last_modified_by"),
    t("label_create_at"),
    t("label_ceateed_by"),
    "",
  ];

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const handleSetDataDetail = (id = "") => {
    dispatch(getPrizeByIdApi(id));
    onOpenDetail();
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <>
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Card px="0px">
          <CardHeader px="22px" mb="12px">
            <Flex
              w="100%"
              gap={4}
              direction={{
                base: "column",
                md: "row",
              }}
              justifyContent={{
                base: "flex-start",
                md: "space-between",
              }}
              alignItems="flex-start"
            >
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                {t("prize_title")}
              </Text>
              <ActionPrizeSettings
                handleOpenCreateOrUpdate={() =>
                  handleOpenCreateOrUpdate(ACTION_PAGE.CREATE)
                }
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
                setParams={setParams}
                params={params}
              />
            </Flex>
          </CardHeader>
          <CardHeader px="42px" mb="32px">
            <ConditionPrize
              isLoading={loadingPrize}
              setParams={setParams}
              params={params}
            />
          </CardHeader>
          <CardBody overflowX="auto">
            <Box overflowX="auto" position="relative">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th width="10px">
                      <CheckBoxType
                        isChecked={
                          dataTable?.length > 0 &&
                          checkedIds.length === dataTable?.length
                        }
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          if (isChecked) {
                            setCheckedIds(
                              dataTable.map((machine) => machine.id)
                            );
                          } else {
                            setCheckedIds([]);
                          }
                        }}
                      ></CheckBoxType>
                    </Th>
                    {labels.map((label) => {
                      return (
                        <Th
                          whiteSpace="nowrap"
                          color="#8392ab"
                          fontWeight="700"
                          fontSize="sm"
                          key={label}
                        >
                          {label}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {!dataTable?.length ? (
                    <NoResultsTableRow
                      numColumns={labels?.length}
                      loadingPage={loadingPrize}
                    />
                  ) : (
                    dataTable.map((prize) => {
                      return (
                        <PrizeRow
                          key={prize?.id}
                          setCheckedIds={setCheckedIds}
                          checkedIds={checkedIds}
                          handleSetDataDetail={handleSetDataDetail}
                          handleOpenCreateOrUpdate={() => {
                            handleOpenCreateOrUpdate(ACTION_PAGE.EDIT, prize);
                          }}
                          listPrize={prize}
                        />
                      );
                    })
                  )}
                </Tbody>
              </Table>
              {loadingPrize ? (
                <Box position="absolute" top={0} left={0} w="100%" h="100%">
                  <LoadingSpinner />
                </Box>
              ) : null}
            </Box>
          </CardBody>
          {(total && (
            <Paginate
              page={params?.page}
              pageCount={total}
              limit={params.limit}
              onChangeLimit={onChangeLimit}
              handleChangePage={handleChangePage}
            />
          )) ||
            ""}
        </Card>

        {isOpenDetail && (
          <PrizeDetail isOpen={isOpenDetail} onClose={handleDetailClose} />
        )}

        {isOpen && (
          <PrizeCreateAndUpdate
            isOpen={isOpen}
            onClose={handleClose}
            dataDetail={dataDetail}
            handle={actionPageRef.current}
            handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
            handleViewClose={handleClose}
          />
        )}
      </Flex>
    </>
  );
}
