import { Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import FormLabelComponent from "components/form/FormLabel";
import { MdClose } from "react-icons/md";

export default function ItemEventPrize({ item, indexItem, removeEventPrize }) {
  const textColor = useColorModeValue("#344767", "white");
  return (
    <Flex
      align="flex-start"
      rowGap={4}
      columnGap={8}
      w="100%"
      justifyContent="space-between"
    >
      <Flex flex={1} direction="column" gap={4}>
        <FormLabelComponent
          fontWeight="700"
          fontSize="16px"
          m="0px"
          title="Event Prize"
        />
        <Text fontWeight="400" fontSize="16px" color="#344767">
          {item?.name}
        </Text>
      </Flex>
      <Flex flex={1} direction="column" gap={4}>
        <FormLabelComponent
          fontWeight="700"
          fontSize="16px"
          m="0px"
          title="Prize Type"
        />
        <Text fontWeight="400" fontSize="16px" color="#344767">
          {item?.prize_type}
        </Text>
      </Flex>
      <Flex flex={1} direction="column" gap={4} color="#344767">
        <FormLabelComponent
          fontWeight="700"
          fontSize="16px"
          m="0px"
          title="Play Fee"
        />
        <Text fontWeight="400" fontSize="16px" color="#344767">
          {item?.play_fee?.value &&
            item?.play_fee?.system_coin &&
            `${item?.play_fee?.value} ${item?.play_fee?.system_coin}`}
        </Text>
      </Flex>
      <Flex flex={1} direction="column" gap={4}>
        <FormLabelComponent
          fontWeight="700"
          fontSize="16px"
          m="0px"
          title="Estimated Value"
        />
        <Text fontWeight="400" fontSize="16px" color="#344767">
          {/* 350 C-Coin */}&nbsp;
        </Text>
      </Flex>
      <Flex direction="column">
        <FormLabelComponent fontWeight="700" fontSize="16px" m="0px" title="" />
        <Icon
          fontSize="26px"
          cursor="pointer"
          onClick={() => {
            removeEventPrize(indexItem);
          }}
          color={textColor}
          key={"remove"}
          as={MdClose}
        />
      </Flex>
    </Flex>
  );
}
