import { Icon, ViewIcon } from "@chakra-ui/icons";
import { Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import moment from "moment";
import { useMemo } from "react";
import { FaFileImport } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { RiUserFollowLine } from "react-icons/ri";
import { OPTIONS_STATUS } from "../constant";

export default function EventManagementRow({
  item,
  checkedIds,
  setCheckedIds,
  handleOpenEditModal,
  handleOpenDetailUserModal,
  handleOpenWhiteModal,
  handleOpenImportWhiteModal,
}) {
  const textColor = useColorModeValue("#344767", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  const convertTime = (time) => {
    return moment(time * 1000)
      .utc()
      .format("DD MMM. YYYY HH:mm:ss");
  };

  const checkStatusEnd = useMemo(() => {
    return item?.status === "CLOSED";
  }, [item?.status]);

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {
            OPTIONS_STATUS.find((status) => status?.value === item?.status)
              ?.label
          }
        </Text>
      </Td>
      <Td>
        <Flex direction="column" whiteSpace="nowrap">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertTime(item?.event_start_time)}
          </Text>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertTime(item?.event_end_time)}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Flex direction="column" whiteSpace="nowrap">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertTime(item?.pre_register_start_time)}
          </Text>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertTime(item?.pre_register_end_time)}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Flex direction="column" whiteSpace="nowrap">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertTime(item?.register_start_time)}
          </Text>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertTime(item?.register_end_time)}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Flex direction="column" whiteSpace="nowrap">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertTime(item?.whitelist_start_time)}
          </Text>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertTime(item?.whitelist_end_time)}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Flex direction="column" whiteSpace="nowrap">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertTime(item?.result_start_time)}
          </Text>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertTime(item?.result_end_time)}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {convertTime(item?.update_time)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.update_by}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {convertTime(item?.create_time)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.create_by}
        </Text>
      </Td>
      <Td>
        <Flex gap={6}>
          <Icon
            fontSize="16px"
            cursor={`${!checkStatusEnd ? "pointer" : "not-allowed"}`}
            onClick={() => {
              if (checkStatusEnd) {
                handleOpenImportWhiteModal(item);
              }
            }}
            color={textColor}
            opacity={checkStatusEnd ? 1 : 0.5}
            as={FaFileImport}
          />
          <Icon
            fontSize="16px"
            cursor={`${checkStatusEnd ? "pointer" : "not-allowed"}`}
            onClick={() => {
              if (checkStatusEnd) {
                handleOpenWhiteModal(item);
              }
            }}
            color={textColor}
            opacity={!checkStatusEnd ? 1 : 0.5}
            as={RiUserFollowLine}
          />
          <Icon
            onClick={() => handleOpenDetailUserModal(item)}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={ViewIcon}
          />
          <Icon
            onClick={() => {
              handleOpenEditModal(item);
            }}
            fontSize="16px"
            color={textColor}
            cursor={"pointer"}
            as={MdEdit}
          />
        </Flex>
      </Td>
    </Tr>
  );
}
