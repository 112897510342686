/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Chakra imports
import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import ImageDefault from "assets/img/empty-image.png";
import CheckBoxType from "components/Checkbox";
import { MdEdit } from "react-icons/md";
import { getDateUTCLLLL } from "utils/time";

const ProjectCategoryRow = ({
  checkedIds,
  setCheckedIds,
  handleSetDataDetail,
  handleOpenCreateOrUpdate,
  listProjectCategory,
}) => {
  const textColor = useColorModeValue("#344767", "white");

  const handleCheckboxChange = () => {
    if (checkedIds.includes(listProjectCategory?.id)) {
      setCheckedIds(
        checkedIds.filter((checkedId) => checkedId !== listProjectCategory?.id)
      );
    } else {
      setCheckedIds([...checkedIds, listProjectCategory?.id]);
    }
  };

  return (
    <Tr key={listProjectCategory?.id} whiteSpace="break-spaces">
      <Td width="10px">
        <CheckBoxType
          isChecked={checkedIds.includes(listProjectCategory?.id)}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td p="2" minW="60px" padding={"0 28px"}>
        <Box
          bgImage={
            listProjectCategory?.banner_image
              ? listProjectCategory?.banner_image
              : ImageDefault
          }
          w="40px"
          h="40px"
          backgroundSize="cover"
        />
      </Td>
      <Td minW="200px" maxW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listProjectCategory?.name || "---"}
        </Text>
      </Td>
      <Td minW="200px" maxW="350px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listProjectCategory?.description || "---"}
        </Text>
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listProjectCategory?.update_time
            ? getDateUTCLLLL(listProjectCategory?.update_time * 1000)
            : "---"}
        </Text>
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listProjectCategory?.last_modified_by || "Admin"}
        </Text>
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listProjectCategory?.create_time
            ? getDateUTCLLLL(listProjectCategory?.create_time * 1000)
            : "---"}
        </Text>
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listProjectCategory?.create_modified_by || "Admin"}
        </Text>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            onClick={() => handleSetDataDetail(listProjectCategory?.id)}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={ViewIcon}
          />
          <Icon
            onClick={() => handleOpenCreateOrUpdate()}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={MdEdit}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default ProjectCategoryRow;
