import { Box, Flex, FormControl, Icon, Text } from "@chakra-ui/react";
import { UploadCSVIcon } from "components/Icons/uploadCSVIcon";
import { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useController, useFormContext } from "react-hook-form";

export default function FormUploadCSV({
  label = "Select CSV file to upload",
  name,
  rules,
  defaultValue,
  wrapperProps,
  handleOnChange,
  ...rest
}) {
  const { control } = useFormContext();
  const inputRef = useRef();
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  const handleOnChangeUpload = (e) => {
    if (handleOnChange) {
      handleOnChange(e.target.files);
    } else {
      onChange(e.target.files);
    }
  };

  const onDrop = (acceptedFiles) => {
    if (handleOnChange) {
      handleOnChange(acceptedFiles);
    } else {
      onChange(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...rest,
  });
  return (
    <FormControl
      maxW="100%"
      w="auto"
      isInvalid={error?.message}
      {...wrapperProps}
      {...getRootProps({ className: "dropzone" })}
    >
      <Flex
        direction="column"
        gap={4}
        alignItems="center"
        justifyContent="center"
        border="1px dashed #DBDBDB"
        borderRadius="8px"
        p="28px"
        cursor="pointer"
        onClick={() => {
          inputRef.current?.click();
        }}
        opacity={isDragActive ? 0.5 : 1}
      >
        <Icon as={UploadCSVIcon} w="106px" h="56px" pointerEvents="none" />
        <Box pointerEvents="none">
          <Text fontSize="14px" fontWeight="500">
            {label}
          </Text>
          <Text fontSize="14px" fontWeight="400" color="#48484A">
            Or drag and drop it here
          </Text>
        </Box>
        <input
          ref={inputRef}
          type="file"
          style={{
            display: "none",
          }}
          onChange={handleOnChangeUpload}
          {...getInputProps()}
        />
      </Flex>
    </FormControl>
  );
}
