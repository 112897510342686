/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CheckBoxType from "components/Checkbox";
import Paginate from "components/Paginate";
import NoResultsTableRow from "components/Tables/NoResultsTableRow";
import RevenueSharingRow from "components/Tables/RevenueSharingRow/RevenueSharingRow";
import { ACTION_PAGE, FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
import { getRevenueSharingApi } from "stores/revenueSharing/action";
import { revenueSharingSelector } from "stores/revenueSharing/selector";
import RevenueSharingCreateAndUpdate from "./components/createAndUpdate";
import ConditionRevenueSharing from "./condition";
import ActionRevenueSharing from "./action";

export default function RevenueSharing() {
  const { t } = useTranslation("revenueSharing");
  const dispatch = useDispatch();
  const textColor = useColorModeValue("#344767", "white");
  const [checkedIds, setCheckedIds] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const actionPageRef = useRef(ACTION_PAGE.VIEW);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });
  const { listRevenueSharings, loadingRevenueSharing } = useSelector(
    revenueSharingSelector
  );

  const [dataTable, setDataTable] = useState([]);
  const [total, setTotal] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    onClose();
    setDataDetail(null);
  };

  const getListRevenueSharing = () => {
    dispatch(getRevenueSharingApi(params));
  };

  useEffect(() => {
    getListRevenueSharing();
  }, [params]);

  useEffect(() => {
    if (listRevenueSharings) {
      setDataTable(() => listRevenueSharings?.records || []);
      setTotal(listRevenueSharings?.total_page || 0);
    }
  }, [listRevenueSharings]);

  const handleOpenCreateOrUpdate = (data = null) => {
    setDataDetail(data);
    onOpen();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: FIRST_PAGE,
    });
  }, [params]);

  const labels = [
    {
      title: t("label_payee"),
      minWidth: "150px",
      maxWidth: "150px",
      textAlign: "left",
    },
    {
      title: t("label_sharing_percentage"),
      minWidth: "200px",
      maxWidth: "200px",
      textAlign: "left",
    },
    {
      title: t("label_last_modified_at"),
      minWidth: "200px",
      maxWidth: "",
      textAlign: "left",
    },
    {
      title: t("label_last_modified_by"),
      minWidth: "150px",
      maxWidth: "",
      textAlign: "left",
    },
    {
      title: t("label_create_at"),
      minWidth: "200px",
      maxWidth: "",
      textAlign: "left",
    },
    {
      title: t("label_ceateed_by"),
      minWidth: "150px",
      maxWidth: "",
      textAlign: "left",
    },
    { title: "", minWidth: "100px", maxWidth: "100px", textAlign: "left" },
  ];

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: FIRST_PAGE,
      limit,
    });
  };

  return (
    <>
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Card px="0px">
          <CardHeader px="22px" mb="12px">
            <Flex
              w="100%"
              gap={4}
              direction={{
                base: "column",
                md: "row",
              }}
              justifyContent={{
                base: "flex-start",
                md: "space-between",
              }}
              alignItems="flex-start"
            >
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                {t("revenue_sharing_title")}
              </Text>
              <ActionRevenueSharing
                handleOpenCreateOrUpdate={() => handleOpenCreateOrUpdate()}
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
                setParams={setParams}
                params={params}
              />
            </Flex>
          </CardHeader>
          <CardHeader px="42px" mb="32px">
            <ConditionRevenueSharing setParams={setParams} params={params} />
          </CardHeader>

          <Box position="relative">
            <TableContainer w={"100%"}>
              <Table variant="simple" overflow="auto">
                <Thead>
                  <Tr>
                    <Th width="10px">
                      <CheckBoxType
                        colorScheme={textColor}
                        isChecked={
                          dataTable?.length > 0 &&
                          checkedIds.length === dataTable?.length
                        }
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          if (isChecked) {
                            setCheckedIds(
                              dataTable.map((machine) => machine.id)
                            );
                          } else {
                            setCheckedIds([]);
                          }
                        }}
                      ></CheckBoxType>
                    </Th>
                    {labels.map((label) => {
                      return (
                        <Th
                          key={label.title}
                          whiteSpace="nowrap"
                          color="#8392ab"
                          fontWeight="700"
                          fontSize="sm"
                          minW={label.minWidth}
                          maxW={label.maxWidth}
                          textAlign={label.textAlign}
                        >
                          {label.title}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {!dataTable?.length ? (
                    <NoResultsTableRow
                      numColumns={labels?.length}
                      loadingPage={loadingRevenueSharing}
                    />
                  ) : (
                    dataTable.map((revenueSharing, i) => {
                      return (
                        <RevenueSharingRow
                          key={i}
                          setCheckedIds={setCheckedIds}
                          checkedIds={checkedIds}
                          handleOpenCreateOrUpdate={() => {
                            handleOpenCreateOrUpdate(revenueSharing);
                          }}
                          listRevenueSharing={revenueSharing}
                        />
                      );
                    })
                  )}
                </Tbody>
              </Table>
              {loadingRevenueSharing ? (
                <Box position="absolute" top={0} left={0} w="100%" h="100%">
                  <LoadingSpinner />
                </Box>
              ) : null}
            </TableContainer>
          </Box>

          {(total && (
            <Paginate
              page={params?.page}
              pageCount={total}
              limit={params.limit}
              onChangeLimit={onChangeLimit}
              handleChangePage={handleChangePage}
            />
          )) ||
            ""}
        </Card>

        {isOpen && (
          <RevenueSharingCreateAndUpdate
            isOpen={isOpen}
            onClose={handleClose}
            dataDetail={dataDetail}
            handle={actionPageRef.current}
            handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
            handleViewClose={handleClose}
          />
        )}
      </Flex>
    </>
  );
}
