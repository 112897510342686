import moment from "moment";

export const dateAfterNow = (afterDay, format = "YYYY-MM-DD HH:mm:ss") => {
  return moment().add(afterDay, "day").second(0).format(format);
};

export const convertToIsoString = (time) => {
  return moment(time).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
};

export const convertToLocal = (time, format = "DD MMM. YYYY HH:mm:ss") => {
  return moment(time * 1000).format(format);
};
