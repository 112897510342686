import { StorageKeys } from "constants/enum";
import { getLocalStorage } from "./storage";
import BASE_API from "constants/configUrl";
import moment from "moment";

export const downloadCSV = async (baseUrl) => {
  const token = getLocalStorage(StorageKeys.TOKEN) || "";
  const formatDate = "YYYYMMDDHHmmss";
  const response = await fetch(`${BASE_API.BASE_API_URL}/api/${baseUrl}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const blob = await response.blob();
  const contentDisposition = response.headers.get("Content-Disposition");
  let filename = "player.csv";
  if (contentDisposition && contentDisposition.includes("attachment")) {
    const matches = contentDisposition?.split("=");
    const fileName = matches[1];
    if (fileName) {
      const decodedString = decodeURIComponent(fileName);
      const decodedSplit = decodedString?.split(".csv")?.[0]?.split("_");
      const utcString = decodedSplit?.[1];
      const utcTime = moment.utc(utcString, formatDate);
      const localTime = utcTime.local().format(formatDate);
      filename = `${decodedSplit?.[0]}_${localTime}.csv`;
    }
  }
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};
