import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { importWhiteListCSV } from "api/event.api";
import ButtonType from "components/Button";
import FormUploadCSV from "components/form/FormUploadCSV";
import useWithToast from "hooks/useWithToast";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ItemCSV from "./itemCSV";

export default function ImportWhiteList({ isOpen, onClose, idDetail }) {
  const [fileSelect, setFileSelect] = useState(null);
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const form = useForm();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async () => {
    try {
      if (idDetail) {
        const formData = new FormData();
        formData.append("whitelistUserFile", fileSelect);
        const res = await importWhiteListCSV(idDetail, formData);
        if (res) {
          showToastSuccess({
            title: "Import success",
          });
          setFileSelect(null);
          onClose();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleOnChangeCSV = (files) => {
    if (files?.length > 0) {
      setFileSelect(files[0]);
    }
  };

  const removeCSV = () => {
    setFileSelect(null);
  };

  const renderForm = () => {
    return (
      <FormProvider {...form} w="100%">
        <form
          id="form-import-white-list"
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          <FormUploadCSV
            name="fileCSV"
            handleOnChange={handleOnChangeCSV}
            accept=".csv"
            multiple={false}
          />
          <Flex
            direction="column"
            gap={2}
            w="100%"
            mt={4}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {fileSelect && (
              <ItemCSV name={fileSelect?.name} removeCSV={removeCSV} />
            )}
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="700px">
          <ModalHeader
            borderBottom="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
            color="#000000"
          >{`IMPORT WHITE LIST`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="24px">{renderForm()}</ModalBody>
          <ModalFooter
            borderTop="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
          >
            <ButtonType
              mt={4}
              type="submit"
              m="auto"
              w="120px"
              h="46px"
              borderRadius="5px"
              form="form-import-white-list"
              disabled={!fileSelect}
              isLoading={isSubmitting}
              text="Import"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
