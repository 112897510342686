import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";

export default function PrizeHistoryRow({ item, fontSize = "sm" }) {
  const textColor = useColorModeValue("#344767", "white");

  const renderRank = (from, to) => {
    if (from === to) {
      return from;
    } else {
      return `${from}-${to}`;
    }
  };

  const renderReward = (rewards) => {
    let result = "";
    if (rewards && rewards?.length > 0) {
      result = rewards
        ?.map((item) => `${item?.item_type_id}: ${item?.value}`)
        ?.join(" | ");
    }
    return result;
  };

  return (
    <Tr>
      <Td w="160px">
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {renderRank(item?.ranking_from, item?.ranking_to)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {renderReward(item?.reward?.reward_items)}
        </Text>
      </Td>
    </Tr>
  );
}
