/* eslint-disable no-useless-catch */
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  ADD_MAINTENANCE_FETCH,
  DELETE_MAINTENANCE_BY_ID_FETCH,
  EDIT_MAINTENANCE_BY_ID_FETCH,
  GET_MAINTENANCE_BY_ID_FETCH,
  GET_MAINTENANCE_FETCH,
  MAINTENANCE_NAMESPACE,
} from '../constant'
import { deleteMaintenance, getMaintenance, getMaintenanceById, postMaintenance, putMaintenance } from 'api/maintenance.api'
import { createStandaloneToast } from '@chakra-ui/react'
import { convertParams } from 'utils/object'

const toast = createStandaloneToast()

export const getMaintenanceApi = createAsyncThunk(
  `${MAINTENANCE_NAMESPACE}/${GET_MAINTENANCE_FETCH}`,
  async (request) => {
    try {
      const params = convertParams(request)
      const res = await getMaintenance(params)
      if (res.data.code === 200) {
        return res?.data?.data || []
      } else {
        toast({
          description: res?.data?.message,
          status: 'error',
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: 'error',
        position: 'bottom-right',
      })
      throw error
    }
  },
)

export const addMaintenanceApi = createAsyncThunk(
  `${MAINTENANCE_NAMESPACE}/${ADD_MAINTENANCE_FETCH}`,
  async (data) => {
    try {
      const res = await postMaintenance(data)
      if (res?.status === 200) {
        toast({
          description: 'Add maintenance successful.',
          status: 'success',
          position: 'bottom-right',
        })
        return res?.data
      } else {
        toast({
          description: res?.data?.message || res?.message,
          status: 'error',
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: 'error',
        position: 'bottom-right',
      })
      throw error
    }
  },
)

export const getMaintenanceByIdApi = createAsyncThunk(
  `${MAINTENANCE_NAMESPACE}/${GET_MAINTENANCE_BY_ID_FETCH}`,
  async (params) => {
    try {
      const res = await getMaintenanceById(params)
      if (res?.status === 200) {
        return res?.data
      } else {
        toast({
          description: res?.data?.message || res?.message,
          status: 'error',
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: 'error',
        position: 'bottom-right',
      })
      throw error
    }
  },
)

export const editMaintenanceByIdApi = createAsyncThunk(
  `${MAINTENANCE_NAMESPACE}/${EDIT_MAINTENANCE_BY_ID_FETCH}`,
  async (data) => {
    try {
      const res = await putMaintenance(data)
      if (res?.status === 200) {
        toast({
          description: 'Update maintenance successful.',
          status: 'success',
          position: 'bottom-right',
        })
        return res?.data
      } else {
        toast({
          description: res?.data?.message || res?.message,
          status: 'error',
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: 'error',
        position: 'bottom-right',
      })
      throw error
    }
  },
)

export const deleteMaintenanceByIdApi = createAsyncThunk(
  `${MAINTENANCE_NAMESPACE}/${DELETE_MAINTENANCE_BY_ID_FETCH}`,
  async (params) => {
    try {
      await params?.map(async (param) => {
        const res = await deleteMaintenance(param)
        if (res?.status !== 200) {
          toast({
            description: res?.data?.message || res?.message,
            status: 'error',
            position: 'bottom-right',
          })
        }
      })

      toast({
        description: `Delete maintenance successful.`,
        status: 'success',
        position: 'bottom-right',
      })
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: 'error',
        position: 'bottom-right',
      })
      throw error
    }
  },
)
