import { Button } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import HistoryPlayGameRow from "./row";
import ButtonType from "components/Button";

export default function HistoryPlayGameTable({
  isLoading,
  isShowPagination = true,
  isShowBtnShowFull = false,
  fontSize = "sm",
  data = [],
  params,
  setParams,
  totalPage,
  textNoData,
  handleClickShowFull,
  ...rest
}) {
  const labels = ["Mode", "Opponent", "Status", "Start time", "End time"];

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Card>
      <CardBody overflowX="auto" maxW="80%" m="auto" minH="200px" {...rest}>
        <TableCustom
          labels={labels}
          isLoading={isLoading}
          isCheck={false}
          dataRow={data}
          textNoData={textNoData}
        >
          <>
            {data?.map((item) => {
              return (
                <HistoryPlayGameRow
                  key={item?.id}
                  item={item}
                  fontSize={fontSize}
                />
              );
            })}
          </>
        </TableCustom>
      </CardBody>
      {isShowPagination && (
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      )}
      {isShowBtnShowFull && (
        <ButtonType
          text="Show full history"
          mt={4}
          btnType="dark-outline"
          alignSelf="flex-end"
          w="117px"
          h="26px"
          p="7px 21px"
          fontWeight="400"
          fontSize="12px"
          borderRadius="3px"
          cursor="pointer"
          // backgroundColor="#EDEEFC"
          onClick={() => {
            handleClickShowFull && handleClickShowFull();
          }}
        />
      )}
    </Card>
  );
}
