import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import moment from "moment";
import { useMemo } from "react";
import { MdEdit } from "react-icons/md";

export default function RuleEventManagementRow({
  item,
  checkedIds,
  setCheckedIds,
  handleOpenEditModal,
}) {
  const textColor = useColorModeValue("#344767", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  const convertTime = (time) => {
    return moment(time * 1000).format("DD MMM. YYYY HH:mm:ss");
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.rule_event_items && item?.rule_event_items[0]?.value}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.rule_event_items && item?.rule_event_items[1]?.value}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {convertTime(item?.update_time)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.update_by}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {convertTime(item?.create_time)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.create_by}
        </Text>
      </Td>
      <Td>
        <Flex gap={6}>
          <Icon
            onClick={() => {
              handleOpenEditModal(item);
            }}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={MdEdit}
          />
        </Flex>
      </Td>
    </Tr>
  );
}
