import { Flex, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { changeStatusMachineSetting, getListCraneMachines } from "api/machine.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import { STATUS } from "constants/constants";
import { useCronJob } from "hooks/useCronJob";
import { useCallback, useEffect, useState } from "react";
import ConditionMachineMap from "./condition";
import MainMap from "./mainMap";

export default function MachineMap() {
  const [isLoading, setIsLoading] = useState(false);

  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 50,
  });
  const [machines, setMachines] = useState([]);

  const toast = useToast();
  const textColor = useColorModeValue("#344767", "white");

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getListMachine = useCallback(
    async (newParams = {}, isReStartJon = true) => {
      try {
        if (isReStartJon) {
          stopJob();
        }
        setIsLoading(true);
        const { data } = await getListCraneMachines({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setMachines(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
        if (isReStartJon) {
          startJob();
        }
      }
    },
    [params]
  );

  useEffect(() => {
    getListMachine();
  }, [getListMachine]);

  const switchStatus = async (machine) => {
    const switchStatus = {
      id: machine?.id,
      status:
        machine?.status === STATUS.ACTIVE ? STATUS.NO_ACTIVE : STATUS.ACTIVE,
    };
    return changeStatusMachineSetting(switchStatus);
  };

  const handleSwitchStatus = async (machine) => {
    try {
      setIsLoading(true);
      const res = await switchStatus(machine);
      if (res?.data?.success) {
        getListMachine();
        toast({
          title: "Update success.",
          description: "Update success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const { startJob, stopJob } = useCronJob({
    task: () => {
      getListMachine({}, false);
    },
  });

  useEffect(() => {
    return () => {
      stopJob();
    };
  }, []);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="32px">
          <Flex gap={4}>
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Machines Map
            </Text>
          </Flex>
        </CardHeader>
        <CardHeader px="42px" mb="32px">
          <ConditionMachineMap
            isLoading={isLoading}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardBody overflowX={{ base: "auto" }}>
          <Flex
            p="10px 42px"
            w="100%"
            gap={8}
            alignItems="center"
            justifyContent="flex-start"
          >
            <MainMap
              isLoading={isLoading}
              machines={machines}
              handleSwitchStatus={handleSwitchStatus}
            />
          </Flex>
        </CardBody>
        {machines?.length > params?.limit && (
          <Paginate
            page={params?.page}
            pageCount={totalPage}
            limit={params?.limit}
            onChangeLimit={onChangeLimit}
            handleChangePage={handleChangePage}
          />
        )}
      </Card>
    </Flex>
  );
}
