// Chakra imports
import { ViewIcon } from "@chakra-ui/icons";
import {
  Flex,
  Icon,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CopyComponent from "components/Copy";
import moment from "moment";
import { trimCharacter } from "utils/string";
import { STATUS_CAMERA_OPTIONS, STATUS_MACHINE } from "./constants";

const MachineStateRow = ({
  machine,
  handleSetDataDetail,
  handleSwitchDirection,
}) => {
  const textColor = useColorModeValue("#344767", "white");

  return (
    <Tr>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {machine?.mac}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {machine?.status && STATUS_MACHINE[machine?.status]}
          </Text>
        </Stack>
      </Td>
      <Td minW="180px">
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          <Text>{machine?.cam_front_id}</Text>
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          <Text>
            {
              STATUS_CAMERA_OPTIONS?.find(
                (item) => item?.value === machine?.status_cam_front
              )?.label
            }
          </Text>
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          <Text>{machine?.cam_back_id}</Text>
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          <Text>
            {
              STATUS_CAMERA_OPTIONS?.find(
                (item) => item?.value === machine?.status_cam_back
              )?.label
            }
          </Text>
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {machine?.control_server_ip}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {machine?.control_server_port}
        </Text>
      </Td>
      <Td>
        <Flex direction="column">
          <Flex align="center" gap={4}>
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight={400}
              whiteSpace="nowrap"
            >
              {trimCharacter(machine?.web_link_cam_front, 9, 8)}
            </Text>
            <CopyComponent dataCopy={machine?.web_link_cam_front} />
          </Flex>
          <Flex align="center" gap={4}>
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight={400}
              whiteSpace="nowrap"
            >
              {trimCharacter(machine?.stream_link_cam_front, 9, 8)}
            </Text>
            <CopyComponent dataCopy={machine?.stream_link_cam_front} />
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Flex direction="column">
          <Flex align="center" gap={4}>
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight={400}
              whiteSpace="nowrap"
            >
              {trimCharacter(machine?.web_link_cam_back, 9, 8)}
            </Text>
            <CopyComponent dataCopy={machine?.web_link_cam_back} />
          </Flex>
          <Flex align="center" gap={4}>
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight={400}
              whiteSpace="nowrap"
            >
              {trimCharacter(machine?.stream_link_cam_back, 9, 8)}
            </Text>
            <CopyComponent dataCopy={machine?.stream_link_cam_back} />
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {moment(machine?.update_time * 1000)
            .utc()
            .format("DD MMM YYYY HH:mm:ss")}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {machine?.update_by || "Admin"}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {moment(machine?.create_time * 1000)
            .utc()
            .format("DD MMM YYYY HH:mm:ss")}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {machine?.create_by || "Admin"}
        </Text>
      </Td>
      <Td>
        <Flex gap={6}>
          <Icon
            onClick={() => handleSetDataDetail()}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={ViewIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default MachineStateRow;
