/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

import Paginate from "components/Paginate";
import NoResultsTableRow from "components/Tables/NoResultsTableRow";
import { ACTION_PAGE, FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
// import { PrizeIcon } from 'components/Icons/PrizeIcon'
import { getPlayFeeApi } from "stores/playFee/action";
import { playFeeSelector } from "stores/playFee/selector";
import ConditionPlayFee from "./condition";
import PlayFeeCreateAndUpdate from "./components/createAndUpdate";
import PlayFeeRow from "components/Tables/playFee/PlayFee";

export default function PlayFee() {
  const { t } = useTranslation("playFee");
  const dispatch = useDispatch();
  const textColor = useColorModeValue("#344767", "white");
  const [checkedIds, setCheckedIds] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const actionPageRef = useRef(ACTION_PAGE.VIEW);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });
  const { listPlayFees, loadingPlayFee } = useSelector(playFeeSelector);

  const [dataTable, setDataTable] = useState([]);
  const [total, setTotal] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    onClose();
    setDataDetail(null);
  };

  const getListPlayFee = () => {
    dispatch(getPlayFeeApi(params));
  };

  useEffect(() => {
    getListPlayFee();
  }, [params]);

  useEffect(() => {
    if (listPlayFees) {
      setDataTable(() => listPlayFees?.records || []);
      setTotal(listPlayFees?.total_page || 0);
    }
  }, [listPlayFees]);

  const handleOpenCreateOrUpdate = (data = null) => {
    setDataDetail(data);
    onOpen();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: FIRST_PAGE,
    });
  }, [params]);

  const labels = [
    {
      title: t("label_value"),
      minWidth: "150px",
      maxWidth: "150px",
      textAlign: "left",
    },
    {
      title: t("label_unit"),
      minWidth: "150px",
      maxWidth: "150px",
      textAlign: "left",
    },
    {
      title: t("label_exchange_rate"),
      minWidth: "150px",
      maxWidth: "150px",
      textAlign: "left",
    },
    {
      title: t("label_last_modified_at"),
      minWidth: "200px",
      maxWidth: "",
      textAlign: "left",
    },
    {
      title: t("label_last_modified_by"),
      minWidth: "150px",
      maxWidth: "",
      textAlign: "left",
    },
    {
      title: t("label_create_at"),
      minWidth: "200px",
      maxWidth: "",
      textAlign: "left",
    },
    {
      title: t("label_ceateed_by"),
      minWidth: "150px",
      maxWidth: "",
      textAlign: "left",
    },
    // {title: '', minWidth: '100px', maxWidth: '100px', textAlign: 'left'},
  ];

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: FIRST_PAGE,
      limit,
    });
  };

  return (
    <>
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Card px="0px">
          <CardHeader px="22px" mb="12px">
            <Flex
              w="100%"
              gap={4}
              direction={{
                base: "column",
                md: "row",
              }}
              justifyContent={{
                base: "flex-start",
                md: "space-between",
              }}
              alignItems="flex-start"
            >
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                {t("play_fee_title")}
              </Text>
              <ConditionPlayFee
                isLoading={loadingPlayFee}
                handleOpenCreateOrUpdate={() => handleOpenCreateOrUpdate()}
              />
            </Flex>
          </CardHeader>

          <Box position="relative">
            <TableContainer w={"100%"}>
              <Table variant="simple" overflow="auto">
                <Thead>
                  <Tr>
                    {/* <Th width='10px'>
                      <Checkbox
                        colorScheme='teal'
                        isChecked={
                          dataTable?.length > 0 &&
                          checkedIds.length === dataTable?.length
                        }
                        onChange={(e) => {
                          const isChecked = e.target.checked
                          if (isChecked) {
                            setCheckedIds(dataTable.map((machine) => machine.id))
                          } else {
                            setCheckedIds([])
                          }
                        }}
                      ></Checkbox>
                    </Th> */}
                    {labels.map((label) => {
                      return (
                        <Th
                          key={label.title}
                          whiteSpace="nowrap"
                          color="#8392ab"
                          fontWeight="700"
                          fontSize="sm"
                          minW={label.minWidth}
                          maxW={label.maxWidth}
                          textAlign={label.textAlign}
                        >
                          {label.title}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {!dataTable?.length ? (
                    <NoResultsTableRow
                      numColumns={labels?.length}
                      loadingPage={loadingPlayFee}
                    />
                  ) : (
                    dataTable.map((playFee, i) => {
                      return (
                        <PlayFeeRow
                          key={i}
                          setCheckedIds={setCheckedIds}
                          checkedIds={checkedIds}
                          // handleOpenCreateOrUpdate={() => {
                          //   handleOpenCreateOrUpdate(playFee)
                          // }}
                          listPlayFee={playFee}
                        />
                      );
                    })
                  )}
                </Tbody>
              </Table>
              {loadingPlayFee ? (
                <Box position="absolute" top={0} left={0} w="100%" h="100%">
                  <LoadingSpinner />
                </Box>
              ) : null}
            </TableContainer>
          </Box>

          {(total && (
            <Paginate
              page={params?.page}
              pageCount={total}
              limit={params.limit}
              onChangeLimit={onChangeLimit}
              handleChangePage={handleChangePage}
            />
          )) ||
            ""}
        </Card>

        {isOpen && (
          <PlayFeeCreateAndUpdate
            isOpen={isOpen}
            onClose={handleClose}
            dataDetail={dataDetail}
            handle={actionPageRef.current}
            handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
            handleViewClose={handleClose}
          />
        )}
      </Flex>
    </>
  );
}
