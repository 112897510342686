/* eslint-disable react/prop-types */
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";

const FormInput = ({
  label,
  name,
  rules,
  defaultValue = "",
  wrapperProps,
  isRequired,
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <FormControl
      maxW="100%"
      w="auto"
      isInvalid={error?.message}
      {...wrapperProps}
    >
      {label && (
        <FormLabel whiteSpace="nowrap" color="#344767">
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </FormLabel>
      )}

      <Input color="#344767" onChange={onChange} value={value} {...rest} />
      {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormInput;
