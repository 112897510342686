import { Box, Flex, Image, Text, useToast } from "@chakra-ui/react";
import {
  changeChromaKeyMachine,
  getDetailMachineSettings,
} from "api/machine.api";
import { postUploadImagePrize } from "api/prize.api";
import ButtonType from "components/Button";
import ModalBase from "components/Modal/ModalBase";
import { FormFileUpload } from "components/form/FormFileUpload";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { STATUS } from "constants/constants";
import { NUMBER } from "constants/enum";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { convertParams, omitParams } from "utils/object";
import { checkMaxImage } from "utils/uploadFile";
import { CHROMA_KEY_OPTIONS } from "./constants";
import { reduce } from "lodash";

export default function DetailCameraModal({
  isOpen,
  onClose,
  dataDetail,
  cameraType,
}) {
  const [machineDetail, setMachineDetail] = useState();
  const [imageActiveDefault, setImageActiveDefault] = useState(null);
  const [indexImageActive, setIndexImageActive] = useState(null);

  const { showToastSuccess } = useWithToast();
  const toast = useToast();

  const LIST_IMAGE = [
    "image1",
    "image2",
    "image3",
    "image4",
    "image5",
    "image6",
  ];

  const getColor = (keyColors, index) => {
    if (keyColors) {
      return `#${keyColors?.split(",")?.[index]}`;
    }
    return "#28a745";
  };

  const setValueForImages = (values) => {
    const pramsImage = reduce(
      LIST_IMAGE,
      (acc, item, index) => {
        if (values && values?.length > 0) {
          return { ...acc, [item]: values?.[index] };
        }
        return { ...acc, [item]: "" };
      },
      {}
    );

    return pramsImage;
  };

  const defaultValues = {
    ...setValueForImages(),
    chromaKey: "false",
    color1: "#28a745",
    color2: "#28a745",
    color3: "#28a745",
    excluded_colors1: "#b2586e",
    excluded_colors2: "#b2586e",
    excluded_colors3: "#b2586e",
    start_coords_x: 0,
    start_coords_y: 0,
    end_coords_x: 1,
    end_coords_y: 1,
  };

  const form = useForm({
    defaultValues,
  });

  const { handleSubmit, watch, reset, getValues, setValue } = form;

  const isHaveImage =
    LIST_IMAGE?.filter((item) => {
      return watch(item);
    })?.length > 0;

  const resetCam = (src) => {
    const iframe = document.getElementById("iframe-cam");
    iframe.src = src;
  };

  const removeHash = (string) => {
    return string?.replace("#", "")?.trim();
  };

  const srcCam = useCallback(
    (newParams) => {
      const color1 = removeHash(newParams?.color1);
      const color2 = removeHash(newParams?.color2);
      const color3 = removeHash(newParams?.color3);
      const excluded_colors1 = removeHash(newParams?.excluded_colors1);
      const excluded_colors2 = removeHash(newParams?.excluded_colors2);
      const excluded_colors3 = removeHash(newParams?.excluded_colors3);
      const params = convertParams(
        omitParams({
          chromakey: newParams?.chromaKey,
          image: newParams?.imageActive,
        })
      );
      return cameraType === STATUS.FRONT
        ? `${dataDetail?.web_link_cam_front}&${params}&keyColors=${color1},${color2},${color3}&excludedColors=${excluded_colors1},${excluded_colors2},${excluded_colors3}`
        : `${dataDetail?.web_link_cam_back}&${params}&keyColors=${color1},${color2},${color3}&excludedColors=${excluded_colors1},${excluded_colors2},${excluded_colors3}`;
    },
    [cameraType, dataDetail]
  );

  const getImageParam = (url) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    return params.get("image");
  };

  const joinWithCheck = (arrData, separator = ",") => {
    return LIST_IMAGE.map((item) => {
      if (arrData?.[item]) {
        return arrData?.[item];
      }
    })
      ?.filter((item) => item)
      .join(separator);
  };

  const getMachineDetail = async (id, cameraType) => {
    try {
      const { data } = await getDetailMachineSettings(id);
      if (data?.success) {
        const detail = data?.data;
        const itemCam = detail?.crane_machine?.crane_machine_chromakeys?.find(
          (item) => item?.cam_position === cameraType
        );
        setMachineDetail(itemCam);
        const urlLink =
          cameraType === STATUS.FRONT
            ? detail?.crane_machine?.web_link_cam_front
            : detail?.crane_machine?.web_link_cam_back;

        const imageActive = getImageParam(urlLink);
        setImageActiveDefault(imageActive);
        const imageSplit = itemCam?.background_image_url?.split(",");
        const params = {
          chromaKey: itemCam?.background_image_url
            ? itemCam?.chromakey
              ? "true"
              : "false"
            : "false",
          color1: getColor(itemCam?.key_colors, 0),
          color2: getColor(itemCam?.key_colors, 1),
          color3: getColor(itemCam?.key_colors, 2),
          excluded_colors1: getColor(itemCam?.excluded_colors, 0),
          excluded_colors2: getColor(itemCam?.excluded_colors, 1),
          excluded_colors3: getColor(itemCam?.excluded_colors, 2),
          start_coords_x: itemCam?.start_coords_x,
          start_coords_y: itemCam?.start_coords_y,
          end_coords_x: itemCam?.end_coords_x,
          end_coords_y: itemCam?.end_coords_y,
        };
        const images = setValueForImages(imageSplit);
        console.log("ccc images", images);
        reset({
          ...params,
          ...images,
        });
        if (itemCam?.chromakey) {
          const cam = srcCam({
            ...params,
            imageActive: imageActive,
          });
          resetCam(cam);
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const isCameraActive = useMemo(() => {
    if (cameraType === STATUS.FRONT) {
      return dataDetail?.status_cam_front === STATUS.OK;
    }
    return dataDetail?.status_cam_back === STATUS.OK;
  }, [dataDetail, cameraType]);

  useEffect(() => {
    if (dataDetail?.machine?.id) {
      getMachineDetail(dataDetail?.machine?.id, cameraType);
    }
  }, [dataDetail?.machine?.id, cameraType, isCameraActive]);

  const viewCam = (index, imageActive) => {
    const data = getValues();
    const indexActive = index || indexImageActive;
    let params = data;
    if (!indexActive) {
      params = {
        ...params,
        imageActive: imageActiveDefault,
      };
    } else {
      params = {
        ...params,
        imageActive: imageActive || data?.[`image${indexActive}`],
      };
    }
    const cam = srcCam(params);
    resetCam(cam);
  };

  const onSubmit = async (data) => {
    try {
      const color1 = removeHash(data?.color1);
      const color2 = removeHash(data?.color2);
      const color3 = removeHash(data?.color3);
      const excluded_colors1 = removeHash(data?.excluded_colors1);
      const excluded_colors2 = removeHash(data?.excluded_colors2);
      const excluded_colors3 = removeHash(data?.excluded_colors3);
      const dataSubmit = {
        id: machineDetail?.id,
        crane_machine_id: machineDetail?.crane_machine_id,
        cam_position: cameraType,
        chromakey: data?.chromaKey === "true",
        background_image_url: joinWithCheck(data),
        key_colors: `${color1},${color2},${color3}`,
        excluded_colors: `${excluded_colors1},${excluded_colors2},${excluded_colors3}`,
        start_coords_x: Number(data?.start_coords_x),
        start_coords_y: Number(data?.start_coords_y),
        end_coords_x: Number(data?.end_coords_x),
        end_coords_y: Number(data?.end_coords_y),
      };
      const res = await changeChromaKeyMachine(dataSubmit);
      if (res?.data?.success) {
        viewCam();
        showToastSuccess({
          title: "Machine updated",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event, indexActive, nameField) => {
    const image = event.target.files[0];
    await uploadImageToServe(image, indexActive, nameField);
  };

  const handleDragImage = async (file, indexActive, nameField) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];

    await uploadImageToServe(image, indexActive, nameField);
  };

  const uploadImageToServe = async (
    image,
    indexActive,
    nameField = "image"
  ) => {
    if (image && !image.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      toast({
        description: "The image format is incorrect.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    } else {
      if (checkMaxImage(image)) {
        toast({
          description: "Image must be smaller than 5MB.",
          status: "error",
          position: "bottom-right",
        });
        return false;
      }
    }
    try {
      const request = new FormData();
      request.append("file", image);
      request.append("name", image?.name || "image");
      const { data } = await postUploadImagePrize(request);
      if (data?.code === 200) {
        setValue(nameField, data?.data?.url);
        viewCam(indexActive, data?.data?.url);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderBody = () => {
    return (
      <Flex alignItems="flex-start" justifyContent="center" gap="20px" h="auto">
        <Box maxWidth="450px" w="auto">
          <iframe
            id="iframe-cam"
            title="cam"
            src={
              cameraType === STATUS.FRONT
                ? dataDetail?.web_link_cam_front
                : dataDetail?.web_link_cam_back
            }
            allowFullScreen
            style={{
              width: "720px",
              height: "1280px",
              objectFit: "cover",
              overflow: "hidden",
              transform: "scale(0.5)",
              marginTop: "-300px",
              marginLeft: "-100px",
            }}
          />
        </Box>

        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              maxWidth: "850px",
            }}
          >
            <Flex
              direction="column"
              alignItems="flex-start"
              gap="20px"
              ml="0"
              mt="20px"
            >
              <Flex
                alignItems="flex-start"
                justifyContent="flex-start"
                gap={4}
                flexWrap="wrap"
              >
                {LIST_IMAGE?.map((item, index) => {
                  return (
                    <Flex
                      key={item}
                      direction="column"
                      alignItems="flex-start"
                      gap={4}
                      maxW={{
                        base: "220px",
                        xl: "auto",
                      }}
                    >
                      <FormFileUpload
                        label="Image chroma key"
                        name={item}
                        accept={"image/*"}
                        textButton="Upload Image"
                        textDescription={"Support .png , .jpg, less than 5mb"}
                        onChange={(e) => handleImageChange(e, index + 1, item)}
                        handleDragImage={(val) =>
                          handleDragImage(val, index + 1, item)
                        }
                      />

                      {getValues(item) && (
                        <Image
                          cursor="pointer"
                          w="100px"
                          maxH="250px"
                          h="auto"
                          src={getValues(item)}
                          alt={item}
                          onClick={() => {
                            setIndexImageActive(index + 1);
                            viewCam(index + 1);
                          }}
                        />
                      )}
                    </Flex>
                  );
                })}
              </Flex>
              <FormSelect
                label="Chroma key"
                name="chromaKey"
                disabled={!isHaveImage}
                options={CHROMA_KEY_OPTIONS}
              />
              <Flex alignItems="flex-end" gap={4}>
                <Flex alignItems="flex-end" gap={4}>
                  <FormInput
                    w="70px"
                    type="color"
                    border="none"
                    p="0"
                    name="color1"
                    label="Color key"
                  />
                  <Text mb="6px">{watch("color1")}</Text>
                </Flex>
                {/* <Flex alignItems="flex-end" gap={4}>
                  <FormInput
                    w="70px"
                    type="color"
                    border="none"
                    p="0"
                    name="excluded_colors1"
                    label="Excluded colors"
                  />
                  <Text mb="6px">{watch("excluded_colors1")}</Text>
                </Flex> */}
              </Flex>
              <Flex alignItems="flex-end" gap={4}>
                <Flex alignItems="flex-end" gap={4}>
                  <FormInput
                    w="70px"
                    type="color"
                    border="none"
                    p="0"
                    name="color2"
                    label="Color key"
                  />
                  <Text mb="6px">{watch("color2")}</Text>
                </Flex>
                {/* <Flex alignItems="flex-end" gap={4}>
                  <FormInput
                    w="70px"
                    type="color"
                    border="none"
                    p="0"
                    name="excluded_colors2"
                    label="Excluded colors"
                  />
                  <Text mb="6px">{watch("excluded_colors2")}</Text>
                </Flex> */}
              </Flex>
              <Flex alignItems="flex-end" gap={4}>
                <Flex alignItems="flex-end" gap={4}>
                  <FormInput
                    w="70px"
                    type="color"
                    border="none"
                    p="0"
                    name="color3"
                    label="Color key"
                  />
                  <Text mb="6px">{watch("color3")}</Text>
                </Flex>
                {/* <Flex alignItems="flex-end" gap={4}>
                  <FormInput
                    w="70px"
                    type="color"
                    border="none"
                    p="0"
                    name="excluded_colors3"
                    label="Excluded colors"
                  />
                  <Text mb="6px">{watch("excluded_colors3")}</Text>
                </Flex> */}
              </Flex>

              {/* <Flex alignItems="center" gap="20px">
                <Flex direction="column">
                  <FormInput
                    type="number"
                    min={0}
                    max={1}
                    step={0.1}
                    name="start_coords_x"
                    label="Start coords X"
                    w="80px"
                  />
                  <FormInput
                    type="number"
                    min={0}
                    max={1}
                    step={0.1}
                    name="start_coords_y"
                    label="Start coords Y"
                    w="80px"
                  />
                </Flex>
                <Flex direction="column">
                  <FormInput
                    type="number"
                    min={0}
                    max={1}
                    step={0.1}
                    name="end_coords_x"
                    label="End coords X"
                    w="80px"
                  />
                  <FormInput
                    type="number"
                    min={0}
                    max={1}
                    step={0.1}
                    name="end_coords_y"
                    label="End coords Y"
                    w="80px"
                  />
                </Flex>
              </Flex> */}
              <Flex alignItems="flex-end" gap={4}>
                <ButtonType
                  onClick={() => {
                    viewCam();
                  }}
                >
                  View
                </ButtonType>
                <ButtonType type="submit">Submit</ButtonType>
              </Flex>
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    );
  };

  return (
    <>
      <ModalBase
        maxWContent="1400px"
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader={`${cameraType} CAMERA - ${dataDetail?.mac?.slice(-4)}`}
      >
        {renderBody()}
      </ModalBase>
    </>
  );
}
