import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import moment from "moment";
import { useMemo } from "react";

export default function WhiteListRow({
  item,
  fontSize = "sm",
  setCheckedIds,
  checkedIds,
}) {
  const textColor = useColorModeValue("#344767", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {item?.email}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {item?.wallet}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {moment(item?.create_time * 1000)
            .utc()
            .format("DD MMM. YYYY HH:mm:ss")}
        </Text>
      </Td>
    </Tr>
  );
}
