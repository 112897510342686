import { Flex } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import { FormProvider, useForm } from "react-hook-form";

export default function ConditionRuleEventManagement({
  isLoading,
  params,
  setParams,
}) {
  const form = useForm();
  const { handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      w="100%"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormInput
              w="349px"
              maxW="100%"
              name="keyword"
              label="Search"
              placeholder={"Search by event name"}
            />
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
