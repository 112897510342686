/* eslint-disable react/prop-types */
import { Checkbox, FormControl, FormLabel } from '@chakra-ui/react'
import { useController, useFormContext } from 'react-hook-form'

const FormCheckbox = ({
  t,
  label,
  name,
  rules,
  defaultValue = '',
  wrapperProps,
  options = [],
  isRequired,
  ...rest
}) => {
  const { control } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue })
  return (
    <FormControl
      maxW='100%'
      w='auto'
      isInvalid={error?.message}
      {...wrapperProps}
    >
      {label && (
        <FormLabel whiteSpace='nowrap' color="#344767">
          {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </FormLabel>
      )}
      <Checkbox {...rest} onChange={onChange} value={value} colorScheme='teal'>
        {options.map((opt) => (
          <Checkbox key={opt.value} value={opt.value}>
            {t(opt.label)}
          </Checkbox>
        ))}
      </Checkbox>
    </FormControl>
  )
}

export default FormCheckbox
