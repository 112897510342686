import axios from 'axios'
import { isEmpty, assign } from 'lodash'
import BASE_API from 'constants/configUrl'
import { StorageKeys } from 'constants/enum'
import { clearLocalStorage, getLocalStorage } from 'utils/storage'
import { createStandaloneToast } from '@chakra-ui/react'

const toast = createStandaloneToast()

const singletonEnforcer = Symbol()
class AxiosClient {
  axiosClient

  static axiosClientInstance

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot initialize Axios client single instance')
    }

    this.axiosClient = axios.create({
      baseURL: `${BASE_API.BASE_API_URL}/api/`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    this.getExistTokenOnLocalStorage()

    this.axiosClient.interceptors.request.use(
      (configure) => {
        const token = getLocalStorage(StorageKeys.TOKEN) || ''
        if (token) {
          configure.headers.Authorization = `Bearer ${token}`
        }
        return configure
      },
      (error) => Promise.reject(error),
    )

    this.axiosClient.interceptors.response.use(
      (response) => {
        const { status, data } = response
        return {
          status,
          data,
        }
      },
      (error) => {
        if (error.response) {
          const { data, status } = error.response
          console.log("data response ============> ", data);
          switch (status) {
            case 400:
              // toast({
              //   description: data?.message || data?.messages[0],
              //   status: 'error',
              //   position: 'bottom-right',
              // })
              break
            case 500:
              break
            case 401:
              break
            case 404:
              break
            case 403:
              clearLocalStorage()
              toast({
                description: data?.message || data?.messages[0],
                status: 'error',
                position: 'bottom-right',
              })
              window.location.href = `/#/${window.location.origin}/authentication/sign-in/cover`
              return true
            default:
              break
          }
          throw data
        } else {
          throw error
        }
      },
    )
  }

  static get instance() {
    if (!this.axiosClientInstance) {
      this.axiosClientInstance = new AxiosClient(singletonEnforcer)
    }

    return this.axiosClientInstance
  }

  async getExistTokenOnLocalStorage() {
    const userToken = await getLocalStorage(StorageKeys.TOKEN)
    if (userToken) {
      this.setHeader(userToken)
    }
  }

  setHeader = async (userToken = null) => {
    this.axiosClient.defaults.headers.common.Authorization = `Bearer ${userToken}`
  }

  get = async (resource, slug = '', config = {}) => {
    let { headers } = config
    if (!headers) {
      headers = this.axiosClient.defaults.headers
    }
    slug += ''
    const requestURL = isEmpty(slug) ? `${resource}` : `${resource}/${slug}`
    return this.axiosClient.get(requestURL, {
      data: null,
      ...assign(config, { headers }),
    })
  }

  post = async (resource, data, config = {}) =>
    this.axiosClient.post(
      `${resource}`,
      data,
      assign(config, this.axiosClient.defaults.headers),
    )

  update = async (resource, data, config = {}) =>
    this.axiosClient.put(
      `${resource}`,
      data,
      assign(config, this.axiosClient.defaults.headers),
    )

  put = async (resource, data, config = {}) =>
    this.axiosClient.put(
      `${resource}`,
      data,
      assign(config, this.axiosClient.defaults.headers),
    )

  patch = async (resource, data, config = {}) =>
    this.axiosClient.patch(
      `${resource}`,
      data,
      assign(config, this.axiosClient.defaults.headers),
    )

  delete = async (resource, data, config = {}) =>
    this.axiosClient.delete(`${resource}`, {
      data,
      ...assign(config, { headers: this.axiosClient.defaults.headers }),
    })
}

export default AxiosClient.instance
